import {
  ArrowDropDown,
  ArrowDropUp,
  Block,
  BlockOutlined,
  ExpandMore,
} from '@mui/icons-material';
import {
  Collapse,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,

} from '@mui/material';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ITableActionMenuModel, TableActionMenu } from './actionMenu';
import { DefaultPageSize } from '@/common/constants/constants';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './dataTable.module.scss';
import { EvesButton } from '../../atoms/button/button';
import { useSelector } from 'react-redux';
import { IAppContextModel } from '@/store/app/types';
import { FormateNumber, getVisibleTableColumnDefs } from '@/common/utils/utils';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import EVESPagination from '../../molecules/pagination/pagination';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
export interface IDataTableProps {
  columns: {
    id: string;
    label: string;
    allowSort?: boolean;
    sortKey?: string;
    sortOrder?: string;
    minWidth?: number;
    textAlign?: string;
    width?: string;
    maxWidth?: number;
  }[];
  rows: Record<string, any>[];
  totalRowsCount: number;
  actionsMenu?: ITableActionMenuModel[];
  contextActionsMenu?: ITableActionMenuModel[];
  children?: any;
  onChange?: (params: any) => void;
  selectionMode?: string | boolean;
  onRowSelectionChange?: (rows: any[]) => void;
  onOwnerChange?: (record: any, event: any) => void;
  className?: string;
  onAdminChange?: (record: any, event: any) => void;
  'data-cy'?: string;
  selectedRows?: any[];
  defaultSortField?: string;
  height?: any;
  hideRowsCount?: boolean;
  hideDetailRow?: string;
  hideExpansion?: boolean;
  defaultSortDescending?: boolean;
  isSelectable?: (row: any) => boolean;
  footerStats?: string;
  projectFields?: string[];
  componentName?: string;
  dynamicColor?: boolean;
  isNewCharge?: boolean;
  tablePagination?: boolean;
  onPageChange?: any;
  onRowsPerPageChange?: any;
  page?: number;
  count?: number;
  limit?: number;
  overflowY?: string;
  maxHeight?: string;
  priceModule?: boolean;
  popopOpen?: boolean;
  setLeftPosition?: any;
  customNoRecordsFoundText?:any
}

// eslint-disable-next-line react/display-name
const DataTable = forwardRef((props: IDataTableProps, ref: any) => {
  const [selectedRows, setSelectedRows] = useState(props.selectedRows || []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRowIds, setSelectedRowIds] = useState([] as any[]);
  const {
    columns,
    rows,
    actionsMenu,
    contextActionsMenu,
    onChange,
    selectionMode,
    onRowSelectionChange,
    hideExpansion,
    tablePagination = false,
    onPageChange,
    onRowsPerPageChange,
    count,
    limit,
    page,
    customNoRecordsFoundText
  } = props;
  let dataColumns = columns.filter((c) => c.id !== 'actions');
  dataColumns = props.projectFields?.length
    ? getVisibleTableColumnDefs(dataColumns, props.projectFields)
    : dataColumns;
  const visibleColumns = props.projectFields?.length
    ? getVisibleTableColumnDefs(columns, props.projectFields)
    : columns;
  const [tableState, setTableState] = useState({
    sortFields: props.defaultSortField,
    sortDescending: props.defaultSortDescending || false,
    disableHoverListener: false,
    limit: DefaultPageSize,
  } as {
    sortFields?: string;
    sortDescending: boolean;
    disableHoverListener: boolean;
    limit: number;
  });
  const { t: translate } = useTranslation();

  const [expandedRowIndexes, setExpandedRowIndexes] = useState<number[]>([]);
  const appContext: IAppContextModel = useSelector(
    (state: any) => state.appContext
  );

  const location = useLocation();

  useEffect(() => {
    onChange &&
      onChange({
        sortFields: tableState?.sortFields,
        limit: tableState?.limit,
        sortDescending: tableState?.sortDescending,
      });
    setTableState((currentState) => {
      return {
        ...currentState,
        disableHoverListener: false,
      };
    });
  }, [
    tableState.sortFields,
    tableState.sortDescending,
    tableState.limit,
    props.projectFields,
  ]);

  useEffect(() => {
    setSelectedRowIds(selectedRows.map((row) => row.id));
  }, [selectedRows]);

  useImperativeHandle(ref, () => ({
    resetSelectedRows: () => {
      setSelectedRows([]);
    },
  }));

  useEffect(() => {
    setSelectedRows(props?.selectedRows || []);
  }, [props?.selectedRows]);

  const onSortApplied = (sortKey?: string, sortOrder?: string) => {
    let sortFields = sortKey;
    const currentSortKey = tableState.sortFields?.replace('-', '');

    let sortDescending = !tableState.sortDescending;
    if (sortKey !== currentSortKey) {
      if (
        (sortKey == 'createdOn' || sortKey == 'timestamp') &&
        (props.componentName == 'RFID' || props.componentName == 'Session')
      ) {
        if (sortOrder && sortOrder === 'desc') {
          sortDescending = false;
        } else {
          sortDescending = true;
          sortFields = `-${sortFields}`;
        }
      } else {
        // new sort key
        if (sortOrder && sortOrder === 'desc') {
          sortDescending = true;
          sortFields = `-${sortFields}`;
        } else {
          sortDescending = false;
        }
      }
    } else if (!tableState.sortDescending) {
      sortFields = `-${sortFields}`;
    }
    setTableState((currentState) => {
      return {
        ...currentState,
        sortDescending: sortDescending,
        sortFields: sortFields,
      };
    });
  };

  const onPageIndexChange = () => {
    setTableState((currentState) => {
      return {
        ...currentState,
        limit: props.rows?.length + DefaultPageSize,
      };
    });
  };
  const disableHover = () => {
    setTableState((currentState) => {
      return {
        ...currentState,
        disableHoverListener: !tableState.disableHoverListener,
      };
    });
  };
  const onSelectAllRows = () => {
    if (selectedRows?.length === rows?.length) {
      setSelectedRows([]);
      onRowSelectionChange && onRowSelectionChange([]);
    } else {
      setSelectedRows(
        props.isSelectable
          ? rows.filter((r) => props.isSelectable && props.isSelectable(r))
          : rows
      );
      onRowSelectionChange &&
        onRowSelectionChange(
          props.isSelectable
            ? rows.filter((r) => props.isSelectable && props.isSelectable(r))
            : rows
        );
    }
  };

  const onMultiRowSelect = (row: any) => {
    let newSelectedRows = [...selectedRows];
    const rowId = row?.id;
    if (rowId) {
      if (
        newSelectedRows.some(
          (newRow) =>
            newRow?.id && JSON.stringify(newRow.id) === JSON.stringify(rowId)
        )
      ) {
        newSelectedRows = newSelectedRows.filter(
          (newRow) =>
            newRow?.id && JSON.stringify(newRow.id) !== JSON.stringify(rowId)
        );
      } else {
        newSelectedRows.push(row);
      }
    } else {
      if (
        newSelectedRows.some(
          (newRow) => JSON.stringify(newRow) === JSON.stringify(row)
        )
      ) {
        newSelectedRows = newSelectedRows.filter(
          (newRow) => JSON.stringify(newRow) !== JSON.stringify(row)
        );
      } else {
        newSelectedRows.push(row);
      }
    }
    setSelectedRows(newSelectedRows);
    onRowSelectionChange && onRowSelectionChange(newSelectedRows);
  };

  const onRowSelect = (row: any) => {
    if (selectionMode === 'single') {
      let rows = [...selectedRows];
      const rowId = row?.id;
      if (rowId) {
        if (
          rows.some(
            (newRow) =>
              newRow?.id && JSON.stringify(newRow.id) === JSON.stringify(rowId)
          )
        ) {
          rows = rows.filter(
            (newRow) =>
              newRow?.id && JSON.stringify(newRow.id) !== JSON.stringify(rowId)
          );
        } else {
          rows = [row];
        }
      } else {
        if (
          rows.some((newRow) => JSON.stringify(newRow) === JSON.stringify(row))
        ) {
          rows = rows.filter(
            (newRow) => JSON.stringify(newRow) !== JSON.stringify(row)
          );
        } else {
          rows = [row];
        }
      }
      setSelectedRows(rows);
      onRowSelectionChange && onRowSelectionChange(rows);
    } else {
      onMultiRowSelect(row);
    }
  };

  const expandRow = (rowIndex: number, componentName?: string) => {
    let indexes: any = [];

    if (componentName === 'Session') {
      if (!expandedRowIndexes.includes(rowIndex)) {
        indexes.push(rowIndex);
      }
    } else {
      indexes = [...expandedRowIndexes];
      if (expandedRowIndexes.includes(rowIndex)) {
        indexes = expandedRowIndexes.filter((index) => index !== rowIndex);
      } else {
        indexes.push(rowIndex);
      }
    }
    setExpandedRowIndexes(indexes);
  };

  // eslint-disable-next-line react/prop-types
  const childrenArray = React.Children.toArray((props as any).children);
  const childrens: any =
    childrenArray?.length &&
    childrenArray.reduce(
      (prev, next: any) => ({ ...prev, [next.props.slot]: next }),
      {}
    );
  const detailRow: any = childrenArray.find(
    (c: any) => c.props.slot === 'row-detail'
  );
  const isDetailRowVisibleCallBack = detailRow?.props?.isVisible;
  const height = props.height ?? { height: 'calc(100vh - 275px)' };
  const tableContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleScroll = () => {
      if (tableContainerRef.current && props?.setLeftPosition) {
        const { scrollLeft } = tableContainerRef.current;
        props?.setLeftPosition(scrollLeft);
      }
    };

    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div
      className={
        props?.priceModule
          ? styles.datatablePriceWrapper
          : styles.datatableWrapper
      }
    >
      <TableContainer
        sx={{
          height,
          maxHeight: props?.maxHeight ?? 'none',
          overflowY: 'auto',
        }}
        ref={tableContainerRef}
      >
        <Table
          className={`${styles.datatable} ${props.className}`}
          stickyHeader
          data-cy={`${props['data-cy']}`}
          aria-label='sticky table'
          size='small'
          sx={{
            height: props.rows?.length === 0 ? '100%!important' : 'auto',
          }}
        >
          <TableHead
            className={
              props?.priceModule
                ? styles.datatablePriceHeader
                : styles.datatableHeader
            }
            data-cy='table-head'
          >
            <TableRow>
              {selectionMode && (
                <TableCell key='selection' width={'40px'}>
                  {selectionMode === 'multiple' && (
                    <EvesCheckbox
                      checked={
                        selectedRows?.length === rows?.length &&
                        selectedRows?.length > 0
                      }
                      indeterminate={
                        selectedRows?.length !== rows?.length &&
                        selectedRows?.length > 0
                      }
                      onChange={onSelectAllRows}
                      data-cy='select-all-checkbox'
                    />
                  )}
                </TableCell>
              )}
              {(!hideExpansion && detailRow && (
                <TableCell
                  key='expand'
                  style={{
                    minWidth: 50,
                    textAlign: 'right',
                  }}
                ></TableCell>
              )) ||
                (hideExpansion && (
                  <TableCell key={`expand-${+new Date()}`}></TableCell>
                ))}
              {visibleColumns.map((column) => {
                const tooltipText = column.tooltip;
                return (
                  <TableCell
                    key={`${column.id}${column.label}${+new Date()}`}
                    style={{
                      minWidth: column.minWidth,
                      textAlign: (column.textAlign as any) ?? 'left',
                      backgroundColor:
                        props['data-cy'] === 'pricing-list'
                          ? '#f5f5f5'
                          : '#fafafa',
                      cursor: column.allowSort ? 'pointer' : 'auto',
                      maxWidth: column.maxWidth,
                      width: column.width,
                    }}
                    data-cy={`table-head-${column.id}`}
                  >
                    {column.allowSort ? (
                      <div
                        className={styles.datatableHeaderCell}
                        onClick={() => {
                          onSortApplied(column.sortKey, column.sortOrder);
                        }}
                        data-cy={column.label}
                      >
                        <b>{column.label}</b>
                        {/* {(tableState?.sortFields === 'id' ||
                                      tableState?.sortFields === '-id') &&
                                   column?.label === 'Name' ? (
                                      <Icon
                                         component={
                                            tableState.sortDescending
                                               ? ArrowDropDown
                                               : tableState.sortDescending ===
                                                 undefined
                                               ? Icon
                                               : ArrowDropUp
                                         }
                                         style={{ marginBottom: '-10px' }}
                                      />
                                   ) : null}
                                   {(tableState?.sortFields === 'site.name' ||
                                      tableState?.sortFields ===
                                         '-site.name') &&
                                   column?.label === 'Site' ? (
                                      <Icon
                                         component={
                                            tableState.sortDescending
                                               ? ArrowDropDown
                                               : tableState.sortDescending ===
                                                 undefined
                                               ? Icon
                                               : ArrowDropUp
                                         }
                                         style={{ marginBottom: '-10px' }}
                                      />
                                   ) : null}
                                   {(tableState?.sortFields ===
                                      'siteArea.name' ||
                                      tableState?.sortFields ===
                                         '-siteArea.name') &&
                                   column?.label === 'Site Area' ? (
                                      <Icon
                                         component={
                                            tableState.sortDescending
                                               ? ArrowDropDown
                                               : tableState.sortDescending ===
                                                 undefined
                                               ? Icon
                                               : ArrowDropUp
                                         }
                                         style={{ marginBottom: '-10px' }}
                                      />
                                   ) : null}
                                   {(tableState?.sortFields === 'public' ||
                                      tableState?.sortFields === '-public') &&
                                   column?.label === 'Public' ? (
                                      <Icon
                                         component={
                                            tableState.sortDescending
                                               ? ArrowDropDown
                                               : tableState.sortDescending ===
                                                 undefined
                                               ? Icon
                                               : ArrowDropUp
                                         }
                                         style={{ marginBottom: '-10px' }}
                                      />
                                   ) : null}
                                   {(tableState?.sortFields ===
                                      'chargePointVendor' ||
                                      tableState?.sortFields ===
                                         '-chargePointVendor') &&
                                   column?.label === 'Vendor' ? (
                                      <Icon
                                         component={
                                            tableState.sortDescending
                                               ? ArrowDropDown
                                               : tableState.sortDescending ===
                                                 undefined
                                               ? Icon
                                               : ArrowDropUp
                                         }
                                         style={{ marginBottom: '-10px' }}
                                      />
                                   ) : null} */}
                        {tableState.sortFields === column.sortKey ||
                          tableState.sortFields === `-${column.sortKey}` ? (
                          <Icon
                            component={
                              tableState.sortDescending
                                ? ArrowDropDown
                                : tableState.sortDescending === undefined
                                  ? Icon
                                  : ArrowDropUp
                            }
                            style={{ marginBottom: '-10px' }}
                          />
                        ) : null}
                      </div>
                    ) : (
                      < div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'8px'}} >
                      <b>{column.label}</b>
                      {tooltipText && ( // Conditionally render InfoIcon only if tooltipText exists
                        <Tooltip
                          title={tooltipText}
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#ffffff', // Tooltip background to white
                                color: '#000000',   // Tooltip text color
                                border: '1px solid #ccc', // Optional border
                                boxShadow: 3,
                              },
                            },
                          }}
                        >
                          <InfoOutlinedIcon style={{ fontSize: '1rem', cursor: 'pointer' }} />
                        </Tooltip>
                      )}
                      </div>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody className={styles.datatableBody} data-cy='tableContents'>
            {rows?.map((row: any, rIdx: number) => {
              return (
                <>
                  <TableRow
                    tabIndex={-1}
                    key={`row-${rIdx}${row.id}`}
                    className={styles.datatableRow}
                    data-cy='data-row'
                    sx={{
                      background:
                        props?.className === 'pricing-table' && rIdx % 2 === 1
                          ? '#f5f5f5 !important'
                          : '#fafafa',
                    }}
                  >
                    {selectionMode && (
                      <TableCell
                        key={`checkbox-${rIdx}-${row.id}`}
                        width={'40px'}
                        className={styles.datatableRes}
                      >
                        <EvesCheckbox
                          checked={selectedRows.some((selectedRow) => {
                            if (row?.id && selectedRow?.id) {
                              return (
                                JSON.stringify(row?.id) ===
                                JSON.stringify(selectedRow?.id)
                              );
                            } else {
                              return (
                                JSON.stringify(row) ===
                                JSON.stringify(selectedRow)
                              );
                            }
                          })}
                          onChange={() => onRowSelect(row)}
                          disabled={
                            props.isSelectable
                              ? !props.isSelectable(row)
                              : false
                          }
                          data-cy='select-row-checkbox'
                        />
                      </TableCell>
                    )}
                    {detailRow && (
                      <TableCell
                        key={`expand-${rIdx}-${row.id}`}
                        className={`${styles.datatableCellExpand} ${styles.datatableRes}`}
                      >
                        {!isDetailRowVisibleCallBack ||
                          isDetailRowVisibleCallBack(row) ? (
                          <IconButton
                            aria-label='expand row'
                            size='small'
                            className={styles.datatableButton}
                            onClick={() =>
                              expandRow(rIdx, props?.componentName)
                            }
                          >
                            {expandedRowIndexes.includes(rIdx) ? (
                              <KeyboardArrowDownIcon
                                sx={{ fill: '#212529' }}
                                className={styles.datatableKeyIcon}
                              />
                            ) : (
                              <KeyboardArrowRightIcon
                                sx={{ fill: '#212529' }}
                                className={styles.datatableKeyIcon}
                              />
                            )}
                          </IconButton>
                        ) : (
                          <IconButton
                            size='small'
                            disabled
                            className={styles.datatableButton}
                          >
                            <Block
                              sx={{ fill: '#ffffff00' }}
                              className={styles.datatableIcon}
                            />
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                    {actionsMenu && (
                      <TableCell
                        key={`actions-${rIdx}-${row.id}`}
                        className={`${styles.datatableCellAction} ${styles.datatableRes}`}
                      >
                        <>
                          {actionsMenu.some(
                            (action) =>
                              (!action.visible ||
                                (action.visible && action.visible(row))) ===
                              true
                          ) ? (
                            actionsMenu?.map((action, idx) => {
                              return (
                                (!action.visible ||
                                  (action.visible && action.visible(row))) && (
                                  <Tooltip
                                    key={`tooltip-${idx}`}
                                    title={(action.title(row) || '').toString()}
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor: 'common.white',
                                          color: 'black',
                                          borderStyle: 'solid',
                                          borderWidth: '1px',
                                        },
                                      },
                                    }}
                                    followCursor={false}
                                    placement={'bottom-start'}
                                    enterNextDelay={500}
                                  >
                                    <IconButton
                                      key={`icon-button-${idx}`}
                                      disabled={
                                        action.disabled && action.disabled(row)
                                      }
                                      onClick={(event: any) => {
                                        row.rowIndex = idx;
                                        action.onClick &&
                                          action.onClick(row, event);
                                      }}
                                      data-cy={action['data-cy']}
                                      name={(
                                        action.title(row) || ''
                                      ).toString()}
                                      className={styles.datatableButton}
                                    >
                                      <Icon
                                        key={`icon-${idx}`}
                                        component={action.icon(row)}
                                        color={
                                          action.dynamicColor
                                            ? action.iconColor(row)
                                            : action.iconColor
                                        }
                                        className={styles.datatableIcon}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )
                              );
                            })
                          ) : (
                            <Tooltip
                              key={'tooltip-noAction'}
                              title={'No possible actions'}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: 'common.white',
                                    color: 'black',
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                  },
                                },
                              }}
                              followCursor={false}
                              placement={'bottom-start'}
                              enterNextDelay={500}
                            >
                              <IconButton
                                key={'icon-button-noAction'}
                                disableRipple={true}
                                data-cy={'data-cy-noAction'}
                                className={styles.datatableButton}
                              >
                                <BlockOutlined />
                              </IconButton>
                            </Tooltip>
                          )}
                          {contextActionsMenu &&
                            contextActionsMenu.some(
                              (action) =>
                                !action.visible ||
                                (action.visible && action.visible(row)) === true
                            ) ? (
                            <Tooltip
                              key={`table-action-menu-tooltip-${rIdx}-${row.id}`}
                              title={`${translate('general.tooltips.more')}`}
                              PopperProps={{
                                disablePortal: true,
                              }}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: 'common.white',
                                    color: 'black',
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                  },
                                },
                              }}
                              followCursor={false}
                              placement={'bottom-start'}
                              enterNextDelay={500}
                              disableHoverListener={
                                tableState.disableHoverListener
                              }
                            >
                              <span onClick={disableHover}>
                                <TableActionMenu
                                  key={`table-action-menu-${rIdx}-${row.id}`}
                                  menus={contextActionsMenu}
                                  record={row}
                                  styles={styles}
                                />
                              </span>
                            </Tooltip>
                          ) : (
                            <IconButton
                              key={'icon-button-noAction'}
                              disableRipple={true}
                              data-cy={'data-cy-noAction'}
                              style={{ display: 'none' }}
                            >
                              <BlockOutlined />
                            </IconButton>
                          )}
                        </>
                      </TableCell>
                    )}
                    {dataColumns?.map((column, cIdx) => {
                      return childrens[column.id] ? (
                        <TableCell
                          key={`${column.id}-${cIdx}`}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: (column.textAlign as any) ?? 'left',
                            padding: '0',
                            paddingLeft: '5px',
                            maxWidth: column.maxWidth,
                            // whiteSpace: 'pre-wrap',
                          }}
                          className={styles.datatableRes}
                          data-cy={`test-${column.id}`}
                        >
                          {React.cloneElement(childrens[column.id], {
                            ...row,
                            rowIndex: rIdx,
                          })}
                        </TableCell>
                      ) : (
                        <TableCell
                          title={(row[column.id] || '-')?.toString()}
                          key={`${column.id}-${cIdx}`}
                          data-cy={`test-${column.id}`}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: (column.textAlign as any) ?? 'left',
                            maxWidth: column.maxWidth,
                          }}
                        >
                          {typeof row[column.id] === 'boolean'
                            ? row[column.id] === true
                              ? `${translate('general.yes')}`
                              : `${translate('general.no')}`
                            : row[column.id] || '-'}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {detailRow && expandedRowIndexes.includes(rIdx) && (
                    <TableRow key={`row-details-${rIdx}`}>
                      <TableCell
                        key={`detail-${rIdx}-${row.id}`}
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={
                          columns?.length + 1 + (props.selectionMode ? 1 : 0)
                        }
                      >
                        <Collapse
                          in={expandedRowIndexes.includes(rIdx)}
                          timeout='auto'
                          unmountOnExit
                        >
                          <>
                            {React.cloneElement(childrens['row-detail'], row)}
                          </>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
            {!appContext.showLoader && props.rows?.length === 0 ? (
              <TableRow
                className={styles.datatableEmptyMessage}
                data-cy='no_record_text_field'
              >
                <TableCell
                  className={styles.datatableEmptyMessageCell}
                  data-cy='no-records'
                  colSpan={12}
                >
                  {customNoRecordsFoundText ? `${translate('general.no_records_found_with_Filters')}` : `${translate('general.no_record_found')}`}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>

        {props.rows?.length !== props.totalRowsCount &&
          !tablePagination &&
          props.totalRowsCount > DefaultPageSize &&
          !location?.pathname.includes('energy') ? (
          <div
            className={styles.datatableExpand}
            data-cy='data-table-load-more-btn'
          >
            <EvesButton
              style={{ color: '#fff', textTransform: 'none' }}
              onClick={onPageIndexChange}
              variant='text'
              data-cy='load-more-btn'
            >
              <ExpandMore /> {`${translate('general.more_records')}`}{' '}
              <ExpandMore />
            </EvesButton>
          </div>
        ) : null}
      </TableContainer>
      {tablePagination ? (
        <EVESPagination
          {...{
            onPageChange,
            onRowsPerPageChange,
            count,
            page,
            rowsPerPage: limit,
          }}
        />
      ) : (
        <>
          {!props.hideRowsCount && (
            <div
              className={styles.datatableFooter}
              data-cy={'record_count_container'}
            >
              <div
                className={styles.datatableFooterLeft}
                data-cy='selected_count_view'
              >
                <span>
                  {selectionMode === 'multiple' &&
                    `${translate('general.selected_records')}: ${selectedRows?.length
                    }`}{' '}
                  &nbsp;
                  {props.footerStats}
                </span>
              </div>
              <div
                className={styles.datatableFooterRight}
                data-cy={'record_count_view'}
              >
                {rows?.length !== props.totalRowsCount && !props.isNewCharge ? (
                  <>
                    {`${translate('general.nbr_of_records')}`}:{' '}
                    {FormateNumber(rows?.length, 0, 0)}/
                    {FormateNumber(props.totalRowsCount, 0, 0)}
                  </>
                ) : (
                  <>
                    {`${translate('general.nbr_of_records')}`}:{' '}
                    {props.isNewCharge ? rows?.length : props.totalRowsCount}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default DataTable;
