import { Content } from '@/common/components/templates/content/content';
import { DefaultPageSize } from '@/common/constants/constants';
import {
  LookupEntityType,
  TenantComponents,
  TransactionStatisticsType,
} from '@/common/enums/enums';
import {
  canListChargingStations,
  isActive,
} from '@/common/utils/utils';
import { ArrowRight, Close } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, LinearProgress, Stack } from '@mui/material';
import { CSSProperties, useRef, useState } from 'react';
import { sessionStatsMapping } from '../../shared/sessions';

import { ITransactionsHistoryStateModal } from '../../shared/models/history';
import { EvesIconButton } from '@/common/components/atoms/iconButton/iconButton';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import LookupComponent from '@/common/components/lookupComponent';
import { CellStatus } from '@/common/components/organisms/table/dataTableCells';
import {  withContext } from '@/common/utils/withContext';
import { OWNER_FILTER_ITEMS as Owner } from '@/common/constants/constants';
import { ReactComponent as SmartCharger } from '@/assets/img/gridify/smartcharger.svg';
import MaxPeakDemand from '@/assets/img/gridify/maxPeak.svg';
import InstantPower from '@/assets/img/gridify/instantPower.svg';
import InstalledDemand from '@/assets/img/gridify/installedDemand.svg';
import DemandSetPoint from '@/assets/img/gridify/demandSetPoint.svg';
import { SearchBox } from '@/common/components/searchBox';
import { useLocation } from 'react-router-dom';
import { columnsType } from '@/common/models/model';
import DatetimeRangePicker from '@/common/components/atoms/datetimeRangePicker/datetimeRangePicker';
import { getUserInformation } from '@/services/userProfile';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown } from '@mui/icons-material';
import TransactionDetails from '@/common/components/templates/transactionDetails';
import { Loader } from '@/common/components/atoms/loader/loader';
import ButtonGroup from '@/common/components/molecules/buttonGroup/buttonGroup';
import CommonCardDemandView from '../../shared/components/common-demand-card/common-card';
import { GraphSection } from './components/graphSection';
import { sampleDemandViewData } from './components/sampleData';
import { HeirachyTable } from '../../shared/components/heirarchyTable';
import { ReactComponent as DemandGraph } from '../../../../assets/img/gridify/demandGraph.svg';
import DemandViewSelect from '../../shared/components/FormSelect';
import { SemiCircular } from './components/semiCircular';
import EditChargingStation from '../../../chargingStation/components/chargingStation/components/chargingStationForm';

const DemandInHistory = () => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const firstParam = location?.search?.split('&')[0];
  const parameterCharger =
    firstParam !== '' ? firstParam?.slice(1).split('=') : [];
  const searchParams = new URLSearchParams(location.search);
  const [userId, setUserId] = useState(null);
  const [visualID, setVisualID] = useState(null);
  const [displayLoader, setDisplayLoader] = useState(false);
  const startDate = new Date(new Date().getFullYear(), 0, 1);
  startDate.setSeconds(0);
  const endDate = new Date();
  endDate.setSeconds(0);
  endDate.setHours(23);
  endDate.setMinutes(59);
  const [state, setStateData] = useState({
    connectorID: null,
    defaultIssuer: true,
    isResetBtnDisabled: true,
    issuer: true,
    isTransactionDetailModalOpen: false,
    limit: DefaultPageSize,
    page: 0,
    projectFields: [],
    rows: sampleDemandViewData || [],
    stats: [],
    search: '',
    selectedChargingStationID: '',
    selectedConnectorID: -1,
    selectedTransactionId: -1,
    selectedRecords: [],
    siteID: '',
    siteAreaID: '',
    sortFields: '-timestamp',
    totalRowsCount: 0,
    userID: userId ? userId : '',
    visualTagID: visualID ? visualID : '',
    isChargingStationLookupModalOpen: false,
    chargingStationName:
      parameterCharger?.length > 0 ? parameterCharger[1] : '',
    chargingStationId: parameterCharger?.length > 0 ? parameterCharger[1] : '',
    inactivityStatus: [],
    userName: '',
    showRFIDFilterModal: false,
    showUserLookUpModal: false,
    RFIDFilterValue: '',
    issuerName: '',
    companyName: '',
    siteName: '',
    siteAreaName: '',
    canExport: false,
    canListSiteAreas: false,
    canListSites: false,
    isSiteLookupModalOpen: false,
    isSiteAreaLookupModalOpen: false,
    withSiteArea: true,
    withSite: true,
    withCompany: true,
    withTag: true,
    withCar: true,
    withUser: true,
    statistics: '',
    subStationID: '',
    subStationName: '',
    feederID: '',
    feederName: '',
    transformerID: '',
    transformerName: '',
    isSubStationIdLookupModalOpen: false,
    isFeederIdLookupModalOpen: false,
    isTransformerIdLookupModalOpen: false,
    onlyRecordCount: true,
    showGraph: true,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  } as ITransactionsHistoryStateModal);
  const isOrganizationComponentActive = isActive(TenantComponents.ORGANIZATION);
  const [showfilter, setShowfilter] = useState<boolean>(false);
  const [site, setSite] = useState('');
  const [siteAreas, setSiteAreas] = useState([]);
  const isUserCanListChargingStations = canListChargingStations();
  const defaultSortColumn = '-timestamp';
  const chargingStationLookupRef: any = useRef(null);
  const siteLookupRef: any = useRef(null);
  const siteAreaLookupRef: any = useRef(null);
  const searchBoxRef = useRef<any>();
  const dataTableRef = useRef<any>();
  const consumptionChartRef = useRef<any>();
  const sites = [
    { label: 'Engineering Lab Public', value: 'Engineering Lab Public' },
    { label: 'Engineering Lab Public 2', value: 'Engineering Lab Public 2' },
  ];
  const siteAreass = [
    { label: 'Engineering (public)', value: 'Engineering (public)' },
    { label: 'Engineering (private)', value: 'Engineering (private)' },
  ];
  let isOnClickSiteFilterClear = false;
  let isOnClickSiteAreaFilterClear = false;
  // const { refetch: fetchTransactionsHistory } = useQuery(
  //   'TransactionHistory',
  //   () => {
  //     return getTransactionsHistory(getTransactionHistoryParams());
  //   },
  //   {
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //     onSuccess: (data: any) => {
  //       setDisplayLoader(false);
  //       if (data) {
  //         const canListSites = data.canListSites;
  //         const canListSiteAreas = data.canListSiteAreas;
  //         const canExport = data.canExport;
  //         setStateData((currentData) => {
  //           return {
  //             ...currentData,
  //             // rows: transactionsData,
  //             // totalRowsCount: data.count,
  //             // stats: data.stats,
  //             canListSites,
  //             canListSiteAreas,
  //             canExport,
  //             projectFields: data.projectFields && [...data.projectFields],
  //           };
  //         });
  //         consumptionChartRef?.current?.refreshChart();
  //       }
  //     },
  //     onError: () => {
  //       setDisplayLoader(false);
  //     },
  //   }
  // );

  // const { refetch: fetchHistoryDashboard } = useQuery(
  //   'HistoryDashboard',
  //   () => {
  //     return getInhistoryDashboard(getTransactionHistoryParams());
  //   },
  //   {
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //     onSuccess: (data: any) => {
  //       setDisplayLoader(false);
  //       if (data) {
  //         setStateData((currentData) => {
  //           return {
  //             ...currentData,
  //             stats: data.data,
  //           };
  //         });
  //         consumptionChartRef?.current?.refreshChart();
  //       }
  //     },
  //     onError: () => {
  //       setDisplayLoader(false);
  //     },
  //   }
  // );

  // const { refetch: fetchTransactionsHistoryCount } = useQuery(
  //   'TransactionHistoryCount',
  //   () => {
  //     const getTransactionParameters = getTransactionHistoryParams(true);
  //     return getTransactionsHistory(getTransactionParameters);
  //   },
  //   {
  //     onSuccess: (data: any) => {
  //       setDisplayLoader(false);
  //       if (data) {
  //         setStateData((currentData) => {
  //           return {
  //             ...currentData,
  //             totalRowsCount: data.count,
  //           };
  //         });
  //       }
  //     },
  //     onError: () => {
  //       setDisplayLoader(false);
  //     },
  //   }
  // );

  const getTransactionHistoryParams = (count = false): any => {
    const firstParam = location?.search?.split('&')[0];
    const parameterCharger = firstParam?.slice(1).split('=');
    const TransactionHistoryGetParams: any = {
      Limit: state.limit,
      Page: state.page + 1,
      SortFields: state.sortFields,
      Issuer: state.issuer,
      ChargingStationID: location.search.includes('?ChargingStationID')
        ? parameterCharger[1]
        : state.chargingStationId,
    };
    TransactionHistoryGetParams.StartDateTime = state.startDate;
    TransactionHistoryGetParams.EndDateTime = state.endDate;

    if (location?.search?.includes('?ChargingStationID')) {
      setDisplayLoader(true);
    }

    if (isOrganizationComponentActive) {
      TransactionHistoryGetParams.WithSite = true;
      TransactionHistoryGetParams.WithCompany = true;
      TransactionHistoryGetParams.WithSiteArea = true;
      TransactionHistoryGetParams.WithTag = true;
      TransactionHistoryGetParams.WithUser = true;
      TransactionHistoryGetParams.WithCar = true;
      TransactionHistoryGetParams.Statistics =
        TransactionStatisticsType.HISTORY;
    }
    if (state.search !== '') {
      TransactionHistoryGetParams['Search'] = state.search;
    }
    if (count) {
      TransactionHistoryGetParams.OnlyRecordCount = true;
    }
    if (state.issuer !== null) {
      TransactionHistoryGetParams['Issuer'] = state.issuer;
    }
    if (state.defaultIssuer) {
      TransactionHistoryGetParams['Issuer'] = true;
    } else {
      if (state.issuerName === Owner.CurrentOrgAPI) {
        TransactionHistoryGetParams['Issuer'] = true;
      }
      if (state.issuerName === Owner.ExternalOrgAPI) {
        TransactionHistoryGetParams['Issuer'] = false;
      }
    }
    if (state.siteID !== '') {
      TransactionHistoryGetParams['SiteID'] = state.siteID;
    }
    if (state.siteAreaID !== '') {
      TransactionHistoryGetParams['SiteAreaID'] = state.siteAreaID;
    }
    if (state.userID !== '') {
      TransactionHistoryGetParams['UserID'] = state.userID;
    }
    if (state.connectorID !== -1) {
      TransactionHistoryGetParams['ConnectorID'] = state.connectorID;
    }
    if (state.subStationID !== '') {
      TransactionHistoryGetParams['SubStationID'] = state.subStationID;
    }
    if (state.feederID !== '') {
      TransactionHistoryGetParams['FeederID'] = state.feederID;
    }
    if (state.transformerID !== '') {
      TransactionHistoryGetParams['TransformerID'] = state.transformerID;
    }
    if (state.inactivityStatus.length > 0) {
      let inactivityStatus = state.inactivityStatus[0];
      for (let i = 1; i < state.inactivityStatus.length; i++)
        inactivityStatus += '|' + state.inactivityStatus[i];
      TransactionHistoryGetParams['InactivityStatus'] = inactivityStatus;
    }
    const visualTagID: any = searchParams.get('VisualTagID');
    if (state.visualTagID !== '') {
      TransactionHistoryGetParams['VisualTagID'] = state.visualTagID;
    }
    if (!visualID && visualTagID) {
      setVisualID(visualTagID);
      setStateData((currentData) => {
        return {
          ...currentData,
          visualTagID: visualTagID,
          RFIDFilterValue: visualTagID,
          isResetBtnDisabled: false,
        };
      });
      TransactionHistoryGetParams['VisualTagID'] = visualTagID;
    }

    const paramUserId: any = searchParams.get('UserID');
    if (!userId && paramUserId) {
      setUserId(paramUserId);
      getUserInformation(paramUserId).then((res: any) => {
        setStateData((currentData) => {
          return {
            ...currentData,
            userID: paramUserId,
            userName: res.firstName + ' ' + res.name,
            isResetBtnDisabled: false,
          };
        });
      });
      TransactionHistoryGetParams['UserID'] = paramUserId;
    }
    if (state.inactivityStatus.length > 0) {
      let inactivityStatus = state.inactivityStatus[0];
      for (let i = 1; i < state.inactivityStatus.length; i++)
        inactivityStatus += '|' + state.inactivityStatus[i];
      TransactionHistoryGetParams['InactivityStatus'] = inactivityStatus;
    }
    return TransactionHistoryGetParams;
  };

  const ChargerName = (row: any) => {
    const [select, setSelect] = useState(true);
    const handleCharger = () => {
      setStateData((prev) => {
        return {
          ...prev,
          editChargingStationForm: true,
          chargingStationId:'9IOCAH10-50-3',
          siteID:'653948ee9881f87c2a0f4015'
        };
      });
    };
    return (
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <IconButton
          sx={{
            borderRadius: '4px',
            display: row.chargerName ? '' : 'none', cursor: 'pointer',
            height: '35px',
            width: '35px',
            padding: 0,
            boxShadow: '2px 2px 8px 1px #1018281F',
            background: select ? '#3D3AD3' : '#999',
            '&:hover': {
              background: select ? '#3D3AD3' : '#999',
            },
          }}
          onClick={() => setSelect(!select)}
          className='demandViewSmartChargingIcon'
          data-cy='transactionsrefund-refund-button'>
          <DemandGraph width={'16px'} height={'16px'} />
        </IconButton>
        <div style={{ color: '#3D3AD3', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleCharger()}>
          {row?.chargerName}
        </div>
      </div>
    );
  };
  const SiteName = (row: any) => {
    const [select, setSelect] = useState(true);
    const Icon = row.childrens?.length === 0 ? ArrowRight : ArrowDropDown;
    const handleRowClick = (rowc: any, name: any) => {
      if (name == 'site') {
        const finData: any = state.rows.map((item) => {
          if (item.id === row.id) {
            return {
              ...item, childrens: row.childrens?.length > 0 ? [] : sampleDemandViewData.filter((itemc) => itemc.id === item.id)[0]?.childrens
            };
          } else return item;
        });
        setStateData({ ...state, rows: finData });
      }
    };
    return <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '3px' }}>
      <IconButton
        sx={{
          borderRadius: '4px',
          display: row.siteName ? '' : 'none', cursor: 'pointer',
          height: '35px',
          width: '35px',
          padding: 0,
          boxShadow: '2px 2px 8px 1px #1018281F',
          background: select ? '#3D3AD3' : '#999',
          '&:hover': {
            background: select ? '#3D3AD3' : '#999',
          },
        }}
        onClick={() => setSelect(!select)}
        className='demandViewSmartChargingIcon'
        data-cy='transactionsrefund-refund-button'>
        <DemandGraph width={'16px'} height={'16px'} />
      </IconButton>
      <Icon style={{ display: row.siteName ? '' : 'none', cursor: 'pointer' }} onClick={() => handleRowClick(row, 'site')} />
      {row?.siteName}
    </div>;
  };

  const SiteAreaName = (row: any) => {
    const [select, setSelect] = useState(true);
    const Icon = row.childrens?.length === 0 ? ArrowRight : ArrowDropDown;
    const handleRowClick = (rowc: any, name: any) => {
      if (name == 'site') {
        const finData: any = state.rows.map((item) => {
          if (item.id === row.siteId) {
            const childrens = item.childrens.map((itemc) => {
              if (itemc.id === row.id) {
                let data: any = [];
                row.childrens?.length > 0 ? data = [] : sampleDemandViewData.filter((item) => {
                  if (row.siteId === item.id) {
                    data = item.childrens.filter((itemc) => itemc.id === row.id)[0]?.childrens;
                  }
                });
                return { ...itemc, childrens: data };
              } else return itemc;
            });
            return { ...item, childrens: childrens };
          } else return item;
        });
        setStateData({ ...state, rows: finData });
      }
    };
    return <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '3px' }}>
      <IconButton
        sx={{
          borderRadius: '4px',
          display: row.siteAreaName ? '' : 'none', cursor: 'pointer',
          height: '35px',
          width: '35px',
          padding: 0,
          boxShadow: '2px 2px 8px 1px #1018281F',
          background: select ? '#3D3AD3' : '#999',
          '&:hover': {
            background: select ? '#3D3AD3' : '#999',
          },
        }}
        onClick={() => setSelect(!select)}
        className='demandViewSmartChargingIcon'
        data-cy='transactionsrefund-refund-button'>
        <DemandGraph width={'16px'} height={'16px'} />
      </IconButton>
      <Icon style={{ display: row.siteAreaName ? '' : 'none', cursor: 'pointer' }} onClick={() => handleRowClick(row, 'site')} />
      {row?.siteAreaName}
      {row?.siteAreaName && <SmartCharger width={'20px'} height={'20px'} />}
    </div >;
  };

  const columns: columnsType[] = [
    {
      id: 'siteName',
      label: `${translate('sites.title')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 120,
      allowSort: false,
    },
    {
      id: 'siteAreaName',
      label: `${translate('site_areas.title')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 140,
      allowSort: false,
    },
    {
      id: 'chargerName',
      label: `${translate('gridify.demand_view.charger_id')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 100,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'connectors',
      label: `${translate('gridify.demand_view.connectors')}`,
      minWidth: 30,
      textAlign: 'start',
      maxWidth: 60,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'instantkw',
      label: `${translate('gridify.demand_view.instantkw')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 100,
      tooltip:'Instant Power vs Installed Capacity',
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'siteAreaRating',
      label: `${translate('gridify.demand_view.siteAreaRating')}`,
      minWidth: 120,
      textAlign: 'center',
      maxWidth: 180,
      tooltip:'Demand Setpoint vs Site Area Rating',
      allowSort: false,
      projectField: 'siteArea.name',
    }
  ];
  const handleGraph = () => {
    setStateData({ ...state, showGraph: !state.showGraph });
  };
  const onTransactionDetailModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransactionDetailModalOpen: false,
      };
    });
  };

  const onTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onClickresetFilters = () => {
    chargingStationLookupRef?.current?.setFocus();
    searchBoxRef?.current?.resetSearch();
    setStateData((currentData) => {
      return {
        ...currentData,
        search: '',
        isResetBtnDisabled: true,
        visualTagID: '',
        siteID: '',
        siteAreaID: '',
        userID: '',
        chargingStationId: '',
        isOnClickSiteFilterClear: true,
        chargingStationName: '',
        connectorID: '',
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        siteName: '',
        siteAreaName: '',
        RFIDFilterValue: '',
        userName: '',
        defaultIssuer: true,
        subStationID: '',
        subStationName: '',
        feederID: '',
        feederName: '',
        transformerID: '',
        transformerName: '',
      };
    });
  };

  const onFilterApplied = (searchText: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        search: searchText,
        isResetBtnDisabled:
          searchText === '' &&
          currentData.siteName === '' &&
          currentData.siteAreaName === '' &&
          currentData.chargingStationId === '' &&
          (currentData.defaultIssuer ||
            currentData.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const setSiteFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedSite = () => {
    isOnClickSiteFilterClear = true;
    siteLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteID: '',
        siteName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteAreaID &&
          !state.chargingStationId &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const resetSiteFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
      };
    });
  };

  const onSiteSelect = (sites: any) => {
    const siteName =
      sites?.length > 1
        ? `${sites[0].name} (+${sites?.length - 1})`
        : sites?.length && sites[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
        siteID: sites.map((site: any) => site.id).join('|'),
        siteName: siteName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetSiteAreaFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
      };
    });
  };

  const onSiteAreaSelect = (siteareas: any) => {
    const siteAreaName =
      siteareas?.length > 1
        ? `${siteareas[0].name} (+${siteareas?.length - 1})`
        : siteareas?.length && siteareas[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
        siteAreaID: siteareas.map((siteArea: any) => siteArea.id).join('|'),
        siteAreaName: siteAreaName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setSiteAreaFilter = () => {
    if (!isOnClickSiteAreaFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteAreaLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteAreaFilterClear = false;
  };

  const onFormClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        editChargingStationForm: false,
      };
    });
  };

  const onClearSelectedSiteArea = () => {
    isOnClickSiteAreaFilterClear = true;
    siteAreaLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteAreaID: '',
        siteAreaName: '',
        isSiteAreaLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.chargingStationId &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  // useEffect(() => {
  //   fetchTransactionsHistory();
  //   fetchTransactionsHistoryCount();
  //   fetchHistoryDashboard();
  // }, [
  //   state.limit,
  //   state.page,
  //   state.sortFields,
  //   state.chargingStationId,
  //   state.userID,
  //   state.visualTagID,
  //   state.issuerName,
  //   state.siteID,
  //   state.siteAreaID,
  //   state.inactivityStatus,
  //   state.search,
  //   state.connectorID,
  //   state.startDate,
  //   state.endDate,
  //   state.subStationID,
  //   state.feederID,
  //   state.transformerID,
  //   location,
  // ]);

  const resetChargingPlanFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        chargingStationID: '',
        isChargingStationLookupModalOpen: false,
      };
    });
  };

  const onChargingStationSelect = (chargingStations: any) => {
    const chargingStationName =
      chargingStations?.length > 1
        ? `${chargingStations[0].id} (+${chargingStations.length - 1})`
        : chargingStations?.length && chargingStations[0].id;

    setStateData((currentData) => {
      return {
        ...currentData,
        isChargingStationLookupModalOpen: false,
        chargingStationId: chargingStations
          .map((chargingStation: any) => chargingStation.id)
          .join('|'),
        chargingStationName: chargingStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setChargingStationFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isChargingStationLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedChargingStation = () => {
    isOnClickSiteFilterClear = true;
    chargingStationLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.siteAreaID &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const onUserFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserLookUpModal: false,
      };
    });
  };

  const getFormatedRFIDCard = (tag: any) => {
    if (tag.length > 1) {
      return `${tag[0].visualID} (+ ${tag.length - 1})`;
    } else {
      return `${tag[0].visualID}`;
    }
  };

  const onUserSelected = (users: any) => {
    const userName =
      users?.length > 1
        ? `${users[0].firstName} ${users[0].name} (+${users?.length - 1})`
        : users?.length && `${users[0].firstName} ${users[0].name}`;
    setStateData((currentDate) => {
      return {
        ...currentDate,
        userID: users.map((user: any) => user.id).join('|'),
        userName: userName,
        showUserLookUpModal: false,
        isResetBtnDisabled: false,
      };
    });
  };


  const onRFIDFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showRFIDFilterModal: false,
      };
    });
  };

  const onRFIDCardSelected = (selectedTag: any) => {
    const sortedObj = Object.values(selectedTag).sort((a: any, b: any) =>
      a.id > b.id ? 1 : -1
    );
    const selectedItems = sortedObj
      ?.map((sessionTag: any) => sessionTag.visualID)
      .join('|');
    setStateData((currentData) => {
      return {
        ...currentData,
        visualTagID: selectedItems,
        RFIDFilterValue: getFormatedRFIDCard(sortedObj),
        showRFIDFilterModal: false,
        isResetBtnDisabled: false,
      };
    });
  };


  const StatusCell = (row: any) => {
    return (
      <CellStatus
        label={
          row.active
            ? `${translate('tags.activated')}`
            : `${translate('tags.deactivated')}`
        }
        type={row.active ? 'Available' : 'Faulted'}
      />
    );
  };


  const onDateRangeChange = (e: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        startDate: e[0].toISOString(),
        endDate: e[1].toISOString(),
        isResetBtnDisabled: false,
      };
    });
  };

  const resetSubStationIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isSubStationIdLookupModalOpen: false };
    });
  };
  const onSubStationIdSelect = (substations: any) => {
    const subStationName =
      substations?.length > 1
        ? `${substations[0].name} (+${substations?.length - 1})`
        : substations?.length && substations[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSubStationIdLookupModalOpen: false,
        subStationID: substations
          .map((subStation: any) => subStation.name)
          .join('|'),
        subStationName: subStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetFeederIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isFeederIdLookupModalOpen: false };
    });
  };
  const onFeederIdSelect = (feeders: any) => {
    const feederName =
      feeders?.length > 1
        ? `${feeders[0].name} (+${feeders?.length - 1})`
        : feeders?.length && feeders[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isFeederIdLookupModalOpen: false,
        feederID: feeders.map((feeder: any) => feeder.name).join('|'),
        feederName: feederName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetTransformerIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isTransformerIdLookupModalOpen: false };
    });
  };
  const onTransformerIdSelect = (transformer: any) => {
    const feederName =
      transformer?.length > 1
        ? `${transformer[0].name} (+${transformer?.length - 1})`
        : transformer?.length && transformer[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransformerIdLookupModalOpen: false,
        transformerID: transformer.map((trans: any) => trans.name).join('|'),
        transformerName: feederName,
        isResetBtnDisabled: false,
      };
    });
  };


  const InstantPowerCell = (row: any) => {
    const powerTypes = {
      INSTANT_POWER_KW_PERCENT: 'instantPowerKWPercent',
      INSTANT_POWER_KW: 'instantPowerKW',
      MAX_POWER_KW: 'maxPowerKW',
    };

    const transformInstantPower = (
      connectors: any[],
      type: string,
      chargingStation: any
    ) => {
      // let value = 0;
      // let instantPowerKW = 0;
      // let maxPowerKW = 0;
      // switch (type) {
      //   // Compute Instance Power
      //   case powerTypes.INSTANT_POWER_KW_PERCENT:
      //   case powerTypes.INSTANT_POWER_KW:
      //     for (const connector of connectors) {
      //       instantPowerKW += connector.currentInstantWatts;
      //     }
      //     // Watt -> kWatts
      //     instantPowerKW /= 1000;
      //     // Handle decimals
      //     if (instantPowerKW < 10) {
      //       instantPowerKW = roundTo(instantPowerKW, 1);
      //     } else {
      //       instantPowerKW = roundTo(instantPowerKW, 0);
      //     }
      //     if (type === powerTypes.INSTANT_POWER_KW_PERCENT) {
      //       if (instantPowerKW === 0) {
      //         value = 0;
      //       }
      //       value = Math.round(
      //         ((instantPowerKW * 1000) / chargingStation.maximumPower) * 100
      //       );
      //     } else {
      //       value = instantPowerKW;
      //     }
      //     break;

      //   // Compute Max Power
      //   case powerTypes.MAX_POWER_KW:
      //     // Max power already filled?
      //     if (chargingStation.maximumPower > 0) {
      //       // Max power is already assigned on charging station level so take it
      //       maxPowerKW = chargingStation.maximumPower;
      //       // Not set: calculate it from connectors
      //     } else {
      //       if (chargingStation.chargePoints) {
      //         // Add charge point power
      //         for (const chargePoint of chargingStation.chargePoints) {
      //           maxPowerKW += getChargingStationPower(
      //             chargingStation,
      //             chargePoint
      //           );
      //         }
      //       } else {
      //         // Add connector power
      //         for (const connector of connectors) {
      //           maxPowerKW += connector.power;
      //         }
      //       }
      //     }
      //     // Watt -> kWatts
      //     maxPowerKW /= 1000;
      //     value = roundTo(maxPowerKW, 1);
      //     break;
      // }
      // const result = value.toLocaleString(userLanguage.language);
      const result = '100';
      return result;
    };
    return (
      <>
        {(Object.keys(row).includes('chargerName') || Object.keys(row).includes('siteAreaName')) ? <div style={{ margin: '0 16px', width: '196px' }}>
          <div
            className={`d-flex power-bar-text ${row.maximumPower === 0 && 'power-bar-text-error'
              }`}
          >
            {transformInstantPower(row.connectors, 'instantPowerKW', row)}
            &nbsp;/&nbsp;
            {transformInstantPower(row.connectors, 'maxPowerKW', row)} kW
          </div>
          {(
            <LinearProgress
              variant='determinate'
              value={50.3}
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#1BD4ED'
                }
              }}
            />
          )}
        </div> : ''}
      </>
    );
  };


  const SiteAreaRating = (row: any) => {

    return (
      <>
        {(Object.keys(row).includes('siteAreaName')) ?
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {(
              <SemiCircular />
            )}
            <div
              className={`d-flex power-bar-text ${row.maximumPower === 0 && 'power-bar-text-error'
                }`}
            >
              {'500kW'}
              &nbsp;/&nbsp;
              {1400} kW
            </div>
          </div> : ''}
      </>
    );
  };


  const alignStyle: CSSProperties = { alignItems: 'flex-end !important' };

  //Mapping Stats value for InProgess
  const stats = sessionStatsMapping(state.stats);

  return (
    <>
          {state.editChargingStationForm && (
        <EditChargingStation
          onFormClose={onFormClose}
          chargingStationId={state.chargingStationId}
          siteId={state.siteID}
          limit={state.limit}
          fetchAllChargingStations={() => {/* */ }}
          canUpdateChargingStation={false}
          editChargingStationForm={state.editChargingStationForm}
        />
      )}
      {<Loader showLoader={displayLoader} />}
      {state.showUserLookUpModal && (
        <LookupComponent
          title={`${translate('users.select_users')}`}
          showLookupModal={state.showUserLookUpModal}
          onModalClose={onUserFilterModalClose}
          selectionMode='multiple'
          entityType={LookupEntityType.SESSION_USER}
          params={[{ key: 'Issuer', value: true }]}
          onSelect={onUserSelected}
          defaultSortColumn='name'
          data-cy='session-select-user-lookup'
        />
      )}

      {state.showRFIDFilterModal && (
        <LookupComponent
          data-cy='RFIDLookUpModal'
          title={`${translate('tags.select_tags')}`}
          showLookupModal={state.showRFIDFilterModal}
          onModalClose={onRFIDFilterModalClose}
          selectionMode='multiple'
          entityType={LookupEntityType.SESSION_RFID_TAG}
          tableChildren={<StatusCell slot='statusCustomValue' />}
          params={
            state.userID
              ? [
                { key: 'Issuer', value: true },
                { key: 'WithUser', value: true },
                { key: 'UserID', value: state.userID },
              ]
              : [
                { key: 'Issuer', value: true },
                { key: 'WithUser', value: true },
              ]
          }
          onSelect={onRFIDCardSelected}
          defaultSortColumn='id'
        />
      )}

      <Content
        contentTop={
          <Grid>
            <Grid
              container
              item
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Grid item xs={10} lg={9} sm={6} md={8} xl={9}>
                {showfilter && (
                  <Grid
                    container
                    item
                    spacing={2}
                    rowSpacing={1}
                    sx={alignStyle}
                  >
                    <Grid item xs={12} sm={6} md={2.1} lg={2} xl={2}>
                      <Stack>
                        <DatetimeRangePicker
                          isDefaultRangeShortcut={true}
                          defaultStartDate={startDate}
                          defaultEndDate={endDate}
                          startDate={state.startDate}
                          endDate={state.endDate}
                          cleanable={false}
                          onDateRangeChange={onDateRangeChange}
                        />
                      </Stack>
                    </Grid>
                    {isOrganizationComponentActive && (
                      <Grid item xs={12} sm={6} md={2.1} lg={2} xl={2}>
                        <EvesTextbox
                          value={state.siteName}
                          id='history-standard-search'
                          label={`${translate('general.search_site')}`}
                          data-cy='select-site'
                          type='text'
                          variant='standard'
                          onClick={setSiteFilter}
                          onChange={onFilterApplied}
                          ref={siteLookupRef}
                          isFilter={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedSite}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {isOrganizationComponentActive && (
                      <Grid item xs={12} sm={6} md={2.1} lg={2} xl={2}>
                        <EvesTextbox
                          value={state.siteAreaName}
                          id='inProgress-standard-search'
                          label={`${translate('site_areas.titles')}`}
                          data-cy='select-site-area'
                          type='text'
                          variant='standard'
                          onClick={setSiteAreaFilter}
                          onChange={onFilterApplied}
                          isFilter={true}
                          ref={siteAreaLookupRef}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedSiteArea}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {isUserCanListChargingStations && (
                      <Grid item xs={12} sm={6} md={2.3} lg={2} xl={2}>
                        <EvesTextbox
                          value={state.chargingStationName}
                          style={{
                            width: '100%',
                            cursor: 'pointer',
                          }}
                          id='sessions-charging-station-lookup'
                          label={`${translate('chargers.chargers')}`}
                          type='text'
                          variant='standard'
                          data-cy='sessions-charging-station-lookup'
                          onClick={setChargingStationFilter}
                          onChange={onFilterApplied}
                          ref={chargingStationLookupRef}
                          isFilter={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedChargingStation}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    <Grid
                      data-cy='search-field'
                      item
                      xs={12}
                      sm={6}
                      md={3.4}
                      lg={4}
                      xl={4}
                    >
                      <SearchBox
                        ref={searchBoxRef}
                        onChange={onFilterApplied}
                        // onRemoveClick={()=>{}}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container gap={'4px'} alignItems={'center'} spacing={2} rowSpacing={1} mt={'10px'} >
                  <Grid item>
                    <Grid display={'flex'} gap={'16px'}>
                      <DemandViewSelect {...{ options: sites, selected: site, setSelected: setSite, multiple: false, label: 'Site' }} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid display={'flex'} gap={'16px'}>
                      <DemandViewSelect {...{ options: siteAreass, selected: siteAreas, setSelected: setSiteAreas, multiple: true, renderText: 'Selected Areas', label: 'Site Area' }} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <SmartCharger className='smartChargerImage' />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item gap={'10px'} mt={1} xs={2} sm={6} md={4} lg={3} xl={3}>
                <ButtonGroup
                  {...{
                    showfilter,
                    setShowfilter,
                    resetFilters: onClickresetFilters,
                    state,
                    multiple: true,
                    isDemandGraph: true,
                    handleGraph,
                    hideLabel: false,
                    // intervalsAndFunctions: [
                    //   {
                    //     interval: autoRefresh.sessions.IN_HISTORY,
                    //     func: ()=>{},
                    //   },
                    //   {
                    //     interval: autoRefresh.sessions.IN_HISTORY,
                    //     func: ()=>{},
                    //   },
                    // ],
                    // onClickExport: exportSession,
                    // isExport: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={'12px'} xs={12} mb={'6px'} mt={'1px'}>
              <Grid item xs={3}>
                <CommonCardDemandView
                  img={MaxPeakDemand}
                  historyTab={true}
                  headerText='Max Peak Demand (kW)'
                  headerTextDesc='Mar 15, 2024'
                  footerText={'1400 KW'}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonCardDemandView
                  img={InstantPower}
                  historyTab={true}
                  headerText='Instant Power'
                  footerText={'1200 KW'}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonCardDemandView
                  img={InstalledDemand}
                  historyTab={true}
                  headerText='Installed Demand (kW)'
                  footerText={'3200.60 KW'}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonCardDemandView
                  img={DemandSetPoint}
                  historyTab={true}
                  headerText='Demand Set Point'
                  footerText={'3200.60KW'}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        contentBody={
          <Grid sx={{
            borderRadius: '8px',
            border: '1px solid var(--Foundation-Base-on-Light-base-on-light-3, #F5F5F5)',
            background: 'var(--Base-White, #FFF)',
            // height: 'calc(100vh - 290px)'
          }}>
            {state.showGraph && <GraphSection />}
            <Grid data-cy={'demand-view-table'}>
              <HeirachyTable
                ref={dataTableRef}
                className='transaction-history-table'
                rows={state.rows}
                columns={columns}
                defaultSelect={true}
                totalRowsCount={state.totalRowsCount}
                onChange={onTableChange}
                defaultSortField={defaultSortColumn}
                // tab='Demand View'
                defaultSortDescending={true}
                data-cy='demand-view-table'
              >
                <SiteName slot='siteName' />
                <SiteAreaName slot='siteAreaName' />
                <ChargerName slot='chargerName' />
                <InstantPowerCell slot='instantkw' />
                <SiteAreaRating slot='siteAreaRating' />
              </HeirachyTable>
            </Grid>
          </Grid>
        }
      ></Content>
      {state.isChargingStationLookupModalOpen && (
        <LookupComponent
          data-cy='sessions-charging-plan-lookup'
          title={`${translate('chargers.select_chargers')}`}
          showLookupModal={state.isChargingStationLookupModalOpen}
          onModalClose={resetChargingPlanFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.CHARGINGSTATION}
          params={
            state.siteAreaID
              ? [
                { key: 'Issuer', value: state.issuer },
                { key: 'SiteAreaID', value: state.siteAreaID },
              ]
              : [{ key: 'Issuer', value: state.issuer }]
          }
          onSelect={onChargingStationSelect}
          defaultSortColumn='id'
        />
      )}
      {state.isSiteLookupModalOpen && (
        <LookupComponent
          title={`${translate('sites.select_sites')}`}
          showLookupModal={state.isSiteLookupModalOpen}
          onModalClose={resetSiteFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITE}
          params={[{ key: 'Issuer', value: state.issuer }]}
          onSelect={onSiteSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isSiteAreaLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_site_areas')}`}
          showLookupModal={state.isSiteAreaLookupModalOpen}
          onModalClose={resetSiteAreaFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITEAREA}
          params={[
            { key: 'Issuer', value: null },
            { key: 'SiteID', value: state.siteID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onSiteAreaSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isTransactionDetailModalOpen && (
        <TransactionDetails
          transactionId={state.selectedTransactionId}
          chargingStationId={state.selectedChargingStationID}
          connectorId={state.selectedConnectorID}
          onClose={onTransactionDetailModalClose}
          withStopedBy={true}
          popupOpen={state.isTransactionDetailModalOpen}
        />
      )}
      {state.isSubStationIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_substation_id')}`}
          showLookupModal={state.isSubStationIdLookupModalOpen}
          onModalClose={resetSubStationIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SUBSTATIONID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'SubStationID', value: state.subStationID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onSubStationIdSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isFeederIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_feeder_id')}`}
          showLookupModal={state.isFeederIdLookupModalOpen}
          onModalClose={resetFeederIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.FEEDERID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'FeederID', value: state.feederID || null },
            { key: 'WithSite', value: true },
            { key: 'SubStationID', value: state.subStationID || null },
          ]}
          onSelect={onFeederIdSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isTransformerIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_transformer_id')}`}
          showLookupModal={state.isTransformerIdLookupModalOpen}
          onModalClose={resetTransformerIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.TRANSFORMERID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'TransformerID', value: state.transformerID || null },
            { key: 'WithSite', value: true },
            { key: 'FeederID', value: state.feederID || null },
            { key: 'SubStationID', value: state.subStationID || null },
            { key: 'FeederID', value: state.feederID || null },
          ]}
          onSelect={onTransformerIdSelect}
          defaultSortColumn='name'
        />
      )}
    </>
  );
};

export default withContext(DemandInHistory);
