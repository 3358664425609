import { EvesIconButton } from '@/common/components/atoms/iconButton/iconButton';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import FilterSelector from '@/common/components/filterSelector';
import LookupComponent from '@/common/components/lookupComponent';
import Connector from '@/common/components/molecules/connectorTypes/connector';
import { ITableActionMenuModel } from '@/common/components/organisms/table/actionMenu';
import DataTable from '@/common/components/organisms/table/dataTable';
import { Content } from '@/common/components/templates/content/content';
import { DefaultPageSize } from '@/common/constants/constants';
import debounce from 'lodash.debounce';
import { autoRefresh } from '@/common/utils/autoRefreshConfig';
import {
  Filters,
  LookupEntityType,
  TenantComponents,
  TransactionStatisticsType,
} from '@/common/enums/enums';
import {
  canListCars,
  canListChargingStations,
  canListTags,
  canListUsers,
  canUpdateCar,
  isActive,
  isAdmin,
  isBasic,
  isDemo,
  isSiteAdminAcess,
  isSiteOwnerAcess,
} from '@/common/utils/utils';
import { TransactionsInProgressModal } from '@/services/models/session';
import {
  exportSessions,
  getInProgressDashboard,
  getPotentialCurtailment,
  getTransactionsInProgress,
} from '@/services/session';
import { Close, OpenInNew, RemoveRedEye, Stop } from '@mui/icons-material';
import { Grid, InputAdornment, Tooltip } from '@mui/material';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import InActivityCell from '../../shared/components/inActiveCell';
import { ITransactionsInProgressStateModal } from '../../shared/models/inProgress';
import {
  handleDeleteTransactions,
  sessionStatsMapping,
} from '../../shared/sessions';
import { CellStatus } from '@/common/components/organisms/table/dataTableCells';
import { OWNER_FILTER_ITEMS as Owner } from '@/common/constants/constants';
import TransactionDetails from '@/common/components/templates/transactionDetails';
import { SearchBox } from '@/common/components/searchBox';
import { useLocation } from 'react-router-dom';
import { columnsType } from '@/common/models/model';
import {
  getChargingStationById,
  stopSessionTransaction,
} from '@/services/chargingStations';
import { IContextPropsModel, withContext } from '@/common/utils/withContext';
import { disableLoaderForOnce } from '@/common/utils/loadingService';
import ConsumptionChart from '@/common/components/molecules/consumptionChart/chart';
import OwnerFilter from '@/common/components/ownerFilter';
import { useTranslation } from 'react-i18next';
import Config from '../../../../common/constants/config';
import CommonCard from '../../shared/components/common-card';
import session from '../../../../assets/img/sessions/active-sessions.svg';
import consumption from '../../../../assets/img/sessions/consumption.svg';
import financials from '../../../../assets/img/sessions/financials.svg';
import PowerCapacityCard from '../../shared/components/power-capacity-card';
import PotentialCurtailment from '../../shared/components/potential-curtailement';
import ButtonGroup from '@/common/components/molecules/buttonGroup/buttonGroup';
import saveAs from 'file-saver';

const TransactionsInProgress = (props: IContextPropsModel) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const visualID = searchParams.get('VisualID');
  const { t: translate } = useTranslation();
  const startDate = new Date(new Date().getFullYear(), 0, 1);
  startDate.setSeconds(0);
  const endDate = new Date();
  endDate.setSeconds(0);
  endDate.setHours(23);
  endDate.setMinutes(59);

  const [state, setStateData] = useState({
    connectorIDField: -1,
    defaultIssuer: true,
    isResetBtnDisabled: true,
    isTransactionDetailModalOpen: false,
    issuer: null,
    limit: DefaultPageSize,
    page: 0,
    projectFields: [],
    inactivityStatus: [],
    rows: [],
    stats: [],
    search: '',
    selectedChargingStationID: '',
    selectedConnectorID: -1,
    selectedTransactionId: -1,
    siteID: '',
    siteAreaID: '',
    canExport: false,
    sortFields: '-timestamp',
    totalRowsCount: 0,
    userID: '',
    visualTagID: visualID ? visualID : '',
    isChargingStationLookupModalOpen: false,
    chargingStationName: '',
    chargingStationId: '',
    action: '',
    userName: '',
    showRFIDFilterModal: false,
    showUserLookUpModal: false,
    RFIDFilterValue: '',
    connectorID: null,
    canListSites: false,
    canListSiteAreas: false,
    siteName: '',
    siteAreaName: '',
    issuerName: Owner.CurrentOrganization,
    isSiteLookupModalOpen: false,
    isSiteAreaLookupModalOpen: false,
    selectedRecords: [],
    subStationID: '',
    subStationName: '',
    feederID: '',
    feederName: '',
    transformerID: '',
    transformerName: '',
    isSubStationIdLookupModalOpen: false,
    isFeederIdLookupModalOpen: false,
    isTransformerIdLookupModalOpen: false,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    canRemoteStopTransaction:false
  } as ITransactionsInProgressStateModal);

  const [showfilter, setShowfilter] = useState<boolean>(false);
  const [stateOfCharge, setStateOfCharge] = useState<number>(0);
  const isUserAdmin = isAdmin();
  const isUserDemo = isDemo();
  const isBasicUser = isBasic();
  const siteAdminAccess = isSiteAdminAcess()
  const siteOwnerAccess = isSiteOwnerAcess()
  const isUserCanListUsers = canListUsers();
  const isUserCanListCars = canListCars();
  const isUserCanUpdateCar = canUpdateCar();
  const isUserCanListChargingStations = canListChargingStations();
  const isUserCanListTags = canListTags();
  const isOrganizationComponentActive = isActive(TenantComponents.ORGANIZATION);
  const isPricingComponentActive = isActive(TenantComponents.PRICING);
  const isCarComponentActive = isActive(TenantComponents.CAR);
  const searchBoxRef = useRef<any>();
  const defaultSortColumn = '-timestamp';
  const chargingStationLookupRef: any = useRef(null);
  let isOnClickSiteFilterClear = false;
  const UserLookupRef: any = useRef(null);
  const RFIDLookupRef: any = useRef(null);
  const siteLookupRef: any = useRef(null);
  const siteAreaLookupRef: any = useRef(null);
  const consumptionChartRef = useRef<any>();
  const dataTableRef = useRef<any>();
  let isOnClickSiteAreaFilterClear = false;
  const subStationIdLookupRef: any = useRef(null);
  const transformerIdLookupRef: any = useRef(null);
  const feederIdLookupRef: any = useRef(null);

  const { refetch: fetchTransactionsInProgress } = useQuery(
    'TransactionInprogress',
    () => {
      return getTransactionsInProgress(getTransactionInprogressParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        if (data) {
          const canListSites = data.canListSites;
          const canListSiteAreas = data.canListSiteAreas;
          const canExport = data.canExport;
          const transactionsData = data.result.map(
            (d: any) => new TransactionsInProgressModal(d)
          );
          setStateData((currentData) => {
            return {
              ...currentData,
              rows: transactionsData,
              totalRowsCount: data.count,
              canListSites,
              canListSiteAreas,
              canExport,
              projectFields: data.projectFields && [...data.projectFields],
            };
          });
          consumptionChartRef?.current?.refreshChart();
        }
      },
    }
  );

  const { refetch: fetchInProgressDashboard } = useQuery(
    'InprogressDashboard',
    () => {
      return getInProgressDashboard(getTransactionInprogressParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        if (data) {
          setStateData((currentData) => {
            return {
              ...currentData,
              stats: {
                ...currentData.stats,
                ...data.data,
              },
            };
          });
          consumptionChartRef?.current?.refreshChart();
        }
      },
    }
  );

  const getTransactionInprogressParams = (): any => {
    const TransactionInprogressGetParams: any = {
      Limit: state.limit,
      Page: state.page + 1,
      SortFields: state.sortFields,
    };

    if (isOrganizationComponentActive) {
      TransactionInprogressGetParams.WithSite = true;
      TransactionInprogressGetParams.WithCompany = true;
      TransactionInprogressGetParams.WithSiteArea = true;
      TransactionInprogressGetParams.WithTag = true;
      TransactionInprogressGetParams.WithUser = true;
      TransactionInprogressGetParams.WithCar = true;
      TransactionInprogressGetParams.WithChargingStation = true;
      TransactionInprogressGetParams.Statistics =
        TransactionStatisticsType.ONGOING;
    }
    if (stateOfCharge) {
      TransactionInprogressGetParams['StateOfCharge'] = stateOfCharge;
    }
    if (state.search !== '') {
      TransactionInprogressGetParams['Search'] = state.search;
    }
    if (state.visualTagID !== '') {
      TransactionInprogressGetParams['VisualTagID'] = state.visualTagID;
    }
    if (state.siteID !== '') {
      TransactionInprogressGetParams['SiteID'] = state.siteID;
    }
    if (state.siteAreaID !== '') {
      TransactionInprogressGetParams['SiteAreaID'] = state.siteAreaID;
    }
    if (state.userID !== '') {
      TransactionInprogressGetParams['UserID'] = state.userID;
    }
    if (state.connectorID !== null) {
      TransactionInprogressGetParams['ConnectorID'] = state.connectorID;
    }
    if (state.defaultIssuer) {
      TransactionInprogressGetParams['Issuer'] = true;
    } else {
      if (state.issuerName === Owner.CurrentOrgAPI) {
        TransactionInprogressGetParams['Issuer'] = true;
      }
      if (state.issuerName === Owner.ExternalOrgAPI) {
        TransactionInprogressGetParams['Issuer'] = false;
      }
    }
    if (state.chargingStationId) {
      TransactionInprogressGetParams['ChargingStationID'] =
        state.chargingStationId;
    }
    if (state.subStationID !== '') {
      TransactionInprogressGetParams['SubStationID'] = state.subStationID;
    }
    if (state.feederID !== '') {
      TransactionInprogressGetParams['FeederID'] = state.feederID;
    }
    if (state.transformerID !== '') {
      TransactionInprogressGetParams['TransformerID'] = state.transformerID;
    }
    if (state.inactivityStatus.length > 0) {
      let inactivityStatus = state.inactivityStatus[0];
      for (let i = 1; i < state.inactivityStatus.length; i++)
        inactivityStatus += '|' + state.inactivityStatus[i];
      TransactionInprogressGetParams['InactivityStatus'] = inactivityStatus;
    }
    return { ...TransactionInprogressGetParams };
  };

  //   const handleStopMultiple = () => {
  //     state.selectedRecords.map((item) => {
  //       getChargingStationById(item.chargeBoxID, {
  //         WithSite: true,
  //         WithSiteArea: true,
  //       }).then((chargingStationRes: any) => {
  //         if (!chargingStationRes.canStopTransaction) {
  //           props.notificationService?.notify({
  //             message: `${translate(
  //               'chargers.action_error.transaction_stop_not_authorized'
  //             )}`,
  //             type: 'error',
  //           });
  //           return;
  //         }
  //         stopSessionTransaction(item.id).then((sessionStopRes: any) => {
  //           if (sessionStopRes.status === 'Accepted') {
  //             props.notificationService?.notify({
  //               message: `${translate(
  //                 'chargers.stop_transaction_success'
  //               ).replace(/{{chargeBoxID}}/g, `'${item.chargeBoxID}'`)}`,
  //               type: 'success',
  //             });
  //             fetchTransactionsInProgress();
  //             fetchInProgressDashboard();
  //             fetchPotentialCurtailment();
  //           } else {
  //             props.notificationService?.notify({
  //               message: `${translate('chargers.stop_transaction_error')}`,
  //               type: 'error',
  //             });
  //           }
  //         });
  //       });
  //     });
  //   };

  const columns: columnsType[] = [
    {
      id: 'actions',
      label: `${translate('general.actions')}`,
      minWidth: 90,
      textAlign: 'center',
      maxWidth: 170,
      allowSort: false,
      projectField: 'action',
    },
    {
      id: 'id',
      label: `${translate('transactions.sessionId')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'id',
    },
    {
      id: 'timestampDateFormated',
      label: `${translate('transactions.started_at')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 180,
      allowSort: true,
      sortKey: 'timestamp',
      projectField: 'timestamp',
    },
    {
      id: 'chargeBoxID',
      label: `${translate('transactions.charging_station')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 200,
      allowSort: true,
      sortKey: 'chargeBoxID',
      projectField: 'chargeBoxID',
    },
    {
      id: 'connectorId',
      label: `${translate('transactions.connector')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: false,
      projectField: 'connectorId',
    },
    {
      id: 'information',
      label: `${translate('chargers.connector_info_title')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'info',
    },
    {
      id: 'currentTotalDuration',
      label: `${translate('transactions.duration')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'currentTotalDurationSecs',
    },
    {
      id: 'currentTotalInactivitySecs',
      label: `${translate('transactions.inactivity')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'currentTotalInactivitySecs',
    },
    {
      id: 'getCurrentInstantWatts',
      label: `${translate('transactions.current_consumption')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 180,
      allowSort: false,
      projectField: 'currentInstantWatts',
    },
    {
      id: 'getCurrentTotalConsumptionWh',
      label: `${translate('transactions.total_consumption')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: false,
      projectField: 'currentTotalConsumptionWh',
    },
    {
      id: 'getCurrentStateOfCharge',
      label: `${translate('transactions.state_of_charge')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: false,
      projectField: 'currentStateOfCharge',
    },
    {
      id: 'getCurrentCumulatedPrice',
      label: `${translate('transactions.price')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'currentCumulatedPrice',
      visible: isPricingComponentActive,
    },
    {
      id: 'companyName',
      label: `${translate('companies.title')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: false,
      projectField: 'company.name',
      visible: isOrganizationComponentActive,
    },
    {
      id: 'siteName',
      label: `${translate('sites.title')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: false,
      projectField: 'site.name',
      visible: isOrganizationComponentActive,
    },
    {
      id: 'siteAreaName',
      label: `${translate('site_areas.title')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: false,
      projectField: 'siteArea.name',
      visible: isOrganizationComponentActive,
    },
    {
      id: 'userFullName',
      label: `${translate('transactions.user')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 190,
      allowSort: false,
      projectField: 'user',
      visible: isUserCanListUsers,
    },
    {
      id: 'tagID',
      label: `${translate('tags.id')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'tagID',
      visible: isUserCanListUsers,
    },
    {
      id: 'tagVisualID',
      label: `${translate('tags.visual_id')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'tag.visualID',
      visible: isUserCanListUsers,
    },
    {
      id: 'tagDescription',
      label: `${translate('general.description')}`,
      minWidth: 60,
      textAlign: 'left',
      maxWidth: 150,
      allowSort: false,
      projectField: 'tag.description',
      visible: isUserCanListUsers,
    },
    {
      id: 'getCarCatalog',
      label: `${translate('car.title')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: true,
      sortKey: 'carCatalog',
      projectField: 'carCatalog',
      visible: isCarComponentActive && isUserCanListCars,
    },
    {
      id: 'carLicensePlate',
      label: `${translate('cars.license_plate')}`,
      minWidth: 60,
      textAlign: 'center',
      maxWidth: 150,
      allowSort: true,
      sortKey: 'car.licensePlate',
      projectField: 'car.licensePlate',
      visible: isCarComponentActive && isUserCanUpdateCar,
    },
  ];

  const onClickTransactionDetailModal = (record: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        selectedConnectorID: record.connectorId,
        selectedTransactionId: record.id,
        selectedChargingStationID: record.chargeBoxID,
        isTransactionDetailModalOpen: true,
      };
    });
  };

  const onClickStopTransaction = (record: any) => {
    disableLoaderForOnce();
    getChargingStationById(record.chargeBoxID, {
      WithSite: true,
      WithSiteArea: true,
    }).then((chargingStationRes: any) => {
      if (!chargingStationRes.canStopTransaction) {
        props.notificationService?.notify({
          message: 'You are not authorized to stop this session',
          type: 'error',
        });
        return;
      }
      props.dialogService?.showConfirm(
        {
          title: `${translate('general.stop')}`,
          description: `${translate(
            'chargers.stop_transaction_confirm'
          ).replace(/{{chargeBoxID}}/g, `'${record.chargeBoxID}'`)}`,
          confirmType: 'YES_NO',
        },
        (result) => {
          if (result === 'YES') {
            getChargingStationById(record.chargeBoxID, {
              WithSite: true,
              WithSiteArea: true,
            }).then((chargingStationRes: any) => {
              if (!chargingStationRes.canStopTransaction) {
                props.notificationService?.notify({
                  message: `${translate(
                    'chargers.action_error.transaction_stop_not_authorized'
                  )}`,
                  type: 'error',
                });
                return;
              }
              stopSessionTransaction(record.id).then((sessionStopRes: any) => {
                if (sessionStopRes.status === 'Accepted') {
                  props.notificationService?.notify({
                    message: `${translate(
                      'chargers.stop_transaction_success'
                    ).replace(/{{chargeBoxID}}/g, `'${record.chargeBoxID}'`)}`,
                    type: 'success',
                  });
                  fetchTransactionsInProgress();
                  fetchInProgressDashboard();
                } else {
                  props.notificationService?.notify({
                    message: `${translate('chargers.stop_transaction_error')}`,
                    type: 'error',
                  });
                }
              });
            });
          }
        }
      );
    });
  };

  const onTransactionDetailModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransactionDetailModalOpen: false,
      };
    });
  };
  const primaryActions: ITableActionMenuModel[] = [
    // {
    //   title: () => `${translate('general.tooltips.view')}`,
    //   icon: () => RemoveRedEye,
    //   iconColor: 'primary',
    //   onClick: onClickTransactionDetailModal,
    //   'data-cy': 'transactions-view-action',
    // },
    {
      title: () => `${translate('general.stop')}`,
      icon: () => Stop,
      visible: (row:any) => !(isBasicUser && isUserDemo )&& (row.canRemoteStopTransaction) ,
      iconColor: 'error',
      onClick: onClickStopTransaction,
      'data-cy': 'transactions-stop-action',
    },
  ];

  const secondaryActions: ITableActionMenuModel[] = [
    {
      title: () => `${translate('logs.redirect')}`,
      icon: () => OpenInNew,
      iconColor: 'primary',
      onClick: (record: any) => {
        window.open(
          `#/logs?ChargingStationID=${record.chargeBoxID}&StartDateTime=${record.timestamp}&LogLevel=I`,
          '_blank'
        );
      },
      visible: () => isUserAdmin,
      'data-cy': 'navigate-to-logs',
    },
    {
      title: () =>
        `${translate('chargers.smart_charging.charging_plans.redirect')}`,
      icon: () => OpenInNew,
      iconColor: 'primary',
      onClick: (record: any) => {
        window.open(
          `#/charging-stations/charging-plans?ChargingStationID=${record.chargeBoxID}&TransactionID=${record.id}`,
          '_blank'
        );
      },
      visible: () => isUserAdmin,
      'data-cy': 'navigate-to-chargingplans',
    },
  ];

  const onTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: newPage,
      };
    });
  };

  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const onOwnerSelect = (selectedIssuerInfo: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isResetBtnDisabled:
          (selectedIssuerInfo.defaultIssuer ||
            selectedIssuerInfo.issuerName === Owner.CurrentOrgAPI) &&
          !state.search &&
          !state.chargingStationId &&
          !state.siteID &&
          !state.siteAreaID &&
          !state.userID &&
          !state.visualTagID &&
          (state.connectorID == -1 || !state.connectorID),
        issuerName: selectedIssuerInfo.issuerName,
        defaultIssuer: selectedIssuerInfo.defaultIssuer,
        issuer: selectedIssuerInfo.issuer
          ? selectedIssuerInfo.issuer
          : state.issuer,
      };
    });
  };

  const ConnectorCell = (row: any) => {
    return (
      <Tooltip
        title={row?.status}
        PopperProps={{ disablePortal: true }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'common.white',
              color: 'black',
              borderStyle: 'solid',
              borderWidth: '1px',
              position: 'fixed',
              top: -30,
              left: 20,
            },
          },
        }}
        followCursor={false}
        enterNextDelay={500}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Connector {...row} />
        </div>
      </Tooltip>
    );
  };

  useEffect(() => {
    fetchTransactionsInProgress();
    fetchInProgressDashboard();
    // fetchPotentialCurtailment();
  }, [
    state.limit,
    state.page,
    state.sortFields,
    state.issuer,
    state.chargingStationId,
    state.visualTagID,
    state.userID,
    state.connectorID,
    state.siteAreaID,
    state.userID,
    state.search,
    state.connectorID,
    state.defaultIssuer,
    state.issuerName,
    state.subStationID,
    state.feederID,
    state.transformerID,
    state.inactivityStatus,
    stateOfCharge,
    state.siteID  // EVES-1907
  ]);

  const resetChargingPlanFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        chargingStationID: '',
        isChargingStationLookupModalOpen: false,
      };
    });
  };

  const onChargingStationSelect = (chargingStations: any) => {
    const chargingStationName =
      chargingStations?.length > 1
        ? `${chargingStations[0].id} (+${chargingStations.length - 1})`
        : chargingStations?.length && chargingStations[0].id;

    setStateData((currentData) => {
      return {
        ...currentData,
        isChargingStationLookupModalOpen: false,
        chargingStationId: chargingStations
          .map((chargingStation: any) => chargingStation.id)
          .join('|'),
        chargingStationName: chargingStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setChargingStationFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isChargingStationLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onFilterApplied = (searchText: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        search: searchText,
        isResetBtnDisabled:
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          !state.search &&
          !state.siteID &&
          !state.siteAreaID &&
          !state.userID &&
          !state.visualTagID &&
          searchText === '' &&
          (state.connectorID == -1 || !state.connectorID) &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const onClearSelectedChargingStation = () => {
    isOnClickSiteFilterClear = true;
    chargingStationLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          !state.search &&
          !state.siteID &&
          !state.siteAreaID &&
          !state.userID &&
          !state.visualTagID &&
          (state.connectorID == -1 || !state.connectorID) &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const setUserFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserLookUpModal: true,
      };
    });
  };

  const onClearSelectedUser = (event: any) => {
    event && event.stopPropagation();
    setStateData((currentData) => {
      return {
        ...currentData,
        userID: '',
        userName: '',
        isResetBtnDisabled:
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          !state.search &&
          !state.chargingStationId &&
          !state.siteID &&
          !state.siteAreaID &&
          !state.visualTagID &&
          (state.connectorID == -1 || !state.connectorID),
      };
    });
  };

  const onUserFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserLookUpModal: false,
      };
    });
  };

  const getFormatedRFIDCard = (tag: any) => {
    if (tag.length > 1) {
      return `${tag[0].visualID} (+ ${tag.length - 1})`;
    } else {
      return `${tag[0].visualID}`;
    }
  };

  const onUserSelected = (users: any) => {
    const userName =
      users?.length > 1
        ? `${users[0].firstName} ${users[0].name} (+${users?.length - 1})`
        : users?.length && `${users[0].firstName} ${users[0].name}`;
    setStateData((currentDate) => {
      return {
        ...currentDate,
        userID: users.map((user: any) => user.id).join('|'),
        userName: userName,
        showUserLookUpModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const exportSession = () => {
    props.dialogService?.showConfirm(
      {
        title: `${translate('transactions.dialog.export.title')}`,
        description: `${translate('transactions.dialog.export.confirm')}`,
        confirmType: 'YES_NO',
      },
      (result) => {
        if (result === 'YES') {
          const historyParams: any = {
            Issuer: state.issuer,
            StartDateTime: state.startDate,
            EndDateTime: state.endDate,
            // WithCompany: state.withCompany,
            // WithSite: state.withSite,
            // WithSiteArea: state.withSiteArea,
            // WithTag: state.withTag,
            // WithUser: state.withUser,
            // WithCar: state.withCar,
            Statistics: TransactionStatisticsType.ONGOING,
            Status: 'active',
          };
          exportSessions(historyParams).then((res: any) => {
            const blob = new Blob([res]);
            saveAs(blob, 'exported-session.csv');
          });
        }
      }
    );
  };

  const setRFIDFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showRFIDFilterModal: true,
      };
    });
  };

  const onRFIDFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showRFIDFilterModal: false,
      };
    });
  };

  const onClearSelectedRFID = (event: any) => {
    event && event.stopPropagation();
    setStateData((currentData) => {
      return {
        ...currentData,
        visualTagID: '',
        RFIDFilterValue: '',
        isResetBtnDisabled:
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          !state.search &&
          !state.chargingStationId &&
          !state.siteID &&
          !state.siteAreaID &&
          !state.userID &&
          (state.connectorID == -1 || !state.connectorID) &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const onRFIDCardSelected = (selectedTag: any) => {
    const sortedObj = Object.values(selectedTag).sort((a: any, b: any) =>
      a.id > b.id ? 1 : -1
    );
    const selectedItems = sortedObj
      ?.map((sessionTag: any) => sessionTag.visualID)
      .join('|');
    setStateData((currentData) => {
      return {
        ...currentData,
        visualTagID: selectedItems,
        RFIDFilterValue: getFormatedRFIDCard(sortedObj),
        showRFIDFilterModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const setSiteFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedSite = () => {
    isOnClickSiteFilterClear = true;
    siteLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteID: '',
        siteName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          !state.search &&
          !state.chargingStationId &&
          !state.siteAreaID &&
          !state.userID &&
          !state.visualTagID &&
          (state.connectorID == -1 || !state.connectorID) &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const resetSiteFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
      };
    });
  };

  const onSiteSelect = (sites: any) => {
    const siteName =
      sites?.length > 1
        ? `${sites[0].name} (+${sites?.length - 1})`
        : sites?.length && sites[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
        siteID: sites.map((site: any) => site.id).join('|'),
        siteName: siteName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setSiteAreaFilter = () => {
    if (!isOnClickSiteAreaFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteAreaLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteAreaFilterClear = false;
  };

  const onClearSelectedSiteArea = () => {
    isOnClickSiteAreaFilterClear = true;
    siteAreaLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteAreaID: '',
        siteAreaName: '',
        isSiteAreaLookupModalOpen: false,
        isResetBtnDisabled:
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          !state.search &&
          !state.chargingStationId &&
          !state.siteID &&
          !state.userID &&
          !state.visualTagID &&
          (state.connectorID == -1 || !state.connectorID),
      };
    });
  };

  const resetSiteAreaFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
      };
    });
  };

  const onSiteAreaSelect = (siteareas: any) => {
    const siteAreaName =
      siteareas?.length > 1
        ? `${siteareas[0].name} (+${siteareas?.length - 1})`
        : siteareas?.length && siteareas[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
        siteAreaID: siteareas.map((siteArea: any) => siteArea.id).join('|'),
        siteAreaName: siteAreaName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onClickresetFilters = () => {
    searchBoxRef?.current?.resetSearch();
    chargingStationLookupRef?.current?.setFocus();
    onClearSelectedSite();
    onClearSelectedSiteArea();
    onClearSelectedUser(null);
    onClearSelectedRFID(null);
    setStateData((currentData) => {
      return {
        ...currentData,
        issuer: state.defaultIssuer,
        siteAreaID: '',
        chargingStationId: '',
        isOnClickSiteFilterClear: true,
        chargingStationName: '',
        connectorID: null,
        isResetBtnDisabled: true,
        defaultIssuer: true,
        subStationID: '',
        subStationName: '',
        feederID: '',
        feederName: '',
        transformerID: '',
        transformerName: '',
      };
    });
  };

  const StatusCell = (row: any) => {
    return (
      <CellStatus
        label={
          row.active
            ? `${translate('tags.activated')}`
            : `${translate('tags.deactivated')}`
        }
        type={row.active ? 'Available' : 'Faulted'}
      />
    );
  };

  const onConnectorSelect = (connectorID: string[]) => {
    const filter: number[] = [];
    connectorID.forEach((item: string) => {
      filter.push(item[0].charCodeAt(0) - 64);
    });
    setStateData((currentData) => {
      return {
        ...currentData,
        connectorID: filter.join('|'),
        isResetBtnDisabled: false,
      };
    });
    if (connectorID.length === 0) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isResetBtnDisabled:
            (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
            !state.search &&
            !state.chargingStationId &&
            !state.siteID &&
            !state.siteAreaID &&
            !state.userID &&
            !state.visualTagID,
        };
      });
    }
  };

  const onClickDelete = () => {
    handleDeleteTransactions(
      state.selectedRecords,
      props,
      dataTableRef,
      inProgressAutoRefresh,
      setStateData,
      translate
    );
  };

  const alignStyle: CSSProperties = { alignItems: 'flex-end !important' };
  const resetSubStationIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isSubStationIdLookupModalOpen: false };
    });
  };

  const onSubStationIdSelect = (substations: any) => {
    const subStationName =
      substations?.length > 1
        ? `${substations[0].name} (+${substations?.length - 1})`
        : substations?.length && substations[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSubStationIdLookupModalOpen: false,
        subStationID: substations
          .map((subStation: any) => subStation.name)
          .join('|'),
        subStationName: subStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setFeederIdFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isFeederIdLookupModalOpen: true };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const resetFeederIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isFeederIdLookupModalOpen: false };
    });
  };

  const onFeederIdSelect = (feeders: any) => {
    const feederName =
      feeders?.length > 1
        ? `${feeders[0].name} (+${feeders?.length - 1})`
        : feeders?.length && feeders[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isFeederIdLookupModalOpen: false,
        feederID: feeders.map((feeder: any) => feeder.name).join('|'),
        feederName: feederName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onClearSelectedFeederId = () => {
    isOnClickSiteFilterClear = true;
    feederIdLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        feederID: '',
        feederName: '',
        isFeederIdLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const setTransformerIdFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isTransformerIdLookupModalOpen: true };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const resetTransformerIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isTransformerIdLookupModalOpen: false };
    });
  };

  const onTransformerIdSelect = (transformer: any) => {
    const transformerName =
      transformer?.length > 1
        ? `${transformer[0].name} (+${transformer?.length - 1})`
        : transformer?.length && transformer[0].name;

    setStateData((currentData) => {
      return {
        ...currentData,
        isTransformerIdLookupModalOpen: false,
        transformerID: transformer.map((trans: any) => trans.name).join('|'),
        transformerName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onClearSelectedTransformer = () => {
    isOnClickSiteFilterClear = true;
    transformerIdLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        transformerID: '',
        transformerName: '',
        isTransformerIdLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const setSubStationIdFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isSubStationIdLookupModalOpen: true };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedSubStation = () => {
    isOnClickSiteFilterClear = true;
    subStationIdLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        subStationID: '',
        subStationName: '',
        isSubStationLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const handleChangeSlider = (event: Event, newValue: number) => {
    setStateOfCharge(newValue);
  };

  const debouncedOnChange = useMemo(
    () =>
      debounce(handleChangeSlider, Config.advanced.debounceTimeSearchMillis),
    []
  );

  const onSelectedRow = (data: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        selectedRecords: data,
      };
    });
  };

  // const onDateRangeChange = (e: any) => {
  //    setStateData((currentData) => {
  //       return {
  //          ...currentData,
  //          startDate: e[0].toISOString(),
  //          endDate: e[1].toISOString(),
  //          isResetBtnDisabled: false,
  //       };
  //    });
  // };

  // const onInactivitySelect = (selectedItems: string[]) => {
  //    const updatedItems: any = selectedItems.map((item) => {
  //       return filterSelector[Filters.INACTIVITY].value[item];
  //    });
  //    setStateData((currentData) => {
  //       return {
  //          ...currentData,
  //          isResetBtnDisabled: false,
  //          inactivityStatus: updatedItems,
  //       };
  //    });
  //    if (selectedItems.length === 0) {
  //       setStateData((currentData) => {
  //          return {
  //             ...currentData,
  //             isResetBtnDisabled:
  //                !state.search &&
  //                !state.siteID &&
  //                !state.siteAreaID &&
  //                !state.chargingStationId &&
  //                (state.defaultIssuer ||
  //                   state.issuerName === Owner.CurrentOrgAPI) &&
  //                (state.connectorID == -1 || !state.connectorID) &&
  //                (state.startDate.toString() == startDate.toISOString() ||
  //                   state.endDate.toString() == endDate.toISOString()) &&
  //                !state.visualTagID &&
  //                !state.userID,
  //          };
  //       });
  //    }
  // };

  //Mapping Stats value for InProgess
  const stats = sessionStatsMapping(state.stats);
  const inProgressAutoRefresh = () => {
    fetchTransactionsInProgress();
    fetchInProgressDashboard();
  };

  return (
    <>
      {state.showUserLookUpModal && (
        <LookupComponent
          title={`${translate('users.select_users')}`}
          showLookupModal={state.showUserLookUpModal}
          onModalClose={onUserFilterModalClose}
          selectionMode='multiple'
          entityType={LookupEntityType.SESSION_USER}
          params={[{ key: 'Issuer', value: true }]}
          onSelect={onUserSelected}
          defaultSortColumn='name'
          data-cy='session-select-user-lookup'
        />
      )}

      {state.showRFIDFilterModal && (
        <LookupComponent
          data-cy='RFIDLookUpModal'
          title={`${translate('tags.select_tags')}`}
          showLookupModal={state.showRFIDFilterModal}
          onModalClose={onRFIDFilterModalClose}
          selectionMode='multiple'
          entityType={LookupEntityType.SESSION_RFID_TAG}
          tableChildren={<StatusCell slot='statusCustomValue' />}
          params={
            state.userID
              ? [
                  { key: 'Issuer', value: true },
                  { key: 'WithUser', value: true },
                  { key: 'UserID', value: state.userID },
                ]
              : [
                  { key: 'Issuer', value: true },
                  { key: 'WithUser', value: true },
                ]
          }
          onSelect={onRFIDCardSelected}
          defaultSortColumn='id'
        />
      )}

      <Content
        contentTop={
          <Grid>
            <Grid
              container
              item
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Grid item xs={8} lg={9} md={8} sm={6} xl={9}>
                {showfilter && (
                  <Grid
                    container
                    item
                    spacing={2}
                    rowSpacing={1}
                    sx={alignStyle}
                  >
                    <Grid
                      data-cy='owner-dropdown-filter'
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.4}
                      xl={2.4}
                    >
                      <OwnerFilter
                        defaultIssuer={state.defaultIssuer}
                        onChange={onOwnerSelect}
                      />
                    </Grid>
                    {isOrganizationComponentActive && (
                      <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
                        <EvesTextbox
                          value={state.siteName}
                          id='inProgress-standard-search'
                          label={`${translate('general.search_site')}`}
                          data-cy='select-site'
                          type='text'
                          variant='standard'
                          onClick={setSiteFilter}
                          onChange={onFilterApplied}
                          ref={siteLookupRef}
                          isFilter={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedSite}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {isOrganizationComponentActive && (
                      <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
                        <EvesTextbox
                          value={state.siteAreaName}
                          id='inProgress-standard-search'
                          label={`${translate('site_areas.titles')}`}
                          data-cy='select-site-area'
                          type='text'
                          variant='standard'
                          onClick={setSiteAreaFilter}
                          onChange={onFilterApplied}
                          isFilter={true}
                          ref={siteAreaLookupRef}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedSiteArea}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {isUserCanListChargingStations && (
                      <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
                        <EvesTextbox
                          value={state.chargingStationName}
                          style={{
                            width: '100%',
                            cursor: 'pointer',
                          }}
                          id='sessions-charging-station-lookup'
                          label={`${translate('chargers.chargers')}`}
                          type='text'
                          variant='standard'
                          data-cy='sessions-charging-station-lookup'
                          onClick={setChargingStationFilter}
                          onChange={onFilterApplied}
                          ref={chargingStationLookupRef}
                          isFilter={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedChargingStation}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {isUserCanListChargingStations && (
                      <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
                        <FilterSelector
                          filterOption={Filters.CONNECTORS}
                          defaultIssuer={state.defaultIssuer}
                          onChange={onConnectorSelect}
                          isResetBtnDisabled={state.isResetBtnDisabled}
                          data-cy='connector-filter'
                        />
                      </Grid>
                    )}
                    {isUserCanListUsers && (
                      <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
                        <EvesTextbox
                          value={state.userName}
                          id='User-standard-search'
                          label={`${translate('sites.users')}`}
                          data-cy='User-filter'
                          type='text'
                          variant='standard'
                          onClick={setUserFilter}
                          onChange={onFilterApplied}
                          isFilter={true}
                          ref={UserLookupRef}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedUser}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {isUserCanListTags && (
                      <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
                        <EvesTextbox
                          value={state.RFIDFilterValue}
                          id='RFID-standard-search'
                          label={`${translate('users.tags')}`}
                          data-cy='RFID-filter'
                          type='text'
                          variant='standard'
                          onClick={setRFIDFilter}
                          onChange={onFilterApplied}
                          isFilter={true}
                          ref={RFIDLookupRef}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedRFID}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    <Grid
                      data-cy='search-field'
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={7.2}
                      xl={7.2}
                    >
                      <SearchBox
                        ref={searchBoxRef}
                        onChange={onFilterApplied}
                        onRemoveClick={fetchTransactionsInProgress}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item mt={1} xs={4} sm={6} lg={3} xl={3} md={4}>
                <ButtonGroup
                  {...{
                    showfilter,
                    setShowfilter,
                    resetFilters: onClickresetFilters,
                    state,
                    // isExport: true,
                    multiple: true,
                    isSession: true,
                    autoRefreshStatus: true,
                    intervalsAndFunctions: [
                      {
                        interval: autoRefresh.sessions.IN_PROGRESS,
                        func: fetchTransactionsInProgress,
                      },
                      {
                        interval: autoRefresh.sessions.IN_PROGRESS,
                        func: fetchInProgressDashboard,
                      },
                    ],
                    isDelete: true,
                    onClickDelete: onClickDelete,
                    onClickExport: exportSession,
                  }}
                />
              </Grid>
            </Grid>
            { //3insys.atlassian.net/browse/EVES-1790
              ((isUserAdmin) || (isBasicUser && (siteAdminAccess || siteOwnerAccess))) && <Grid container item spacing={'12px'} xs={12} my={'12px'}>  
              <Grid item xs={3}>
                <CommonCard
                  img={session}
                  headerText='Active Sessions'
                  footerText={stats?.session}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonCard
                  img={consumption}
                  headerText='Consumptions'
                  footerText={stats?.consumption}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <CommonCard
                  img={financials}
                  headerText='Financials'
                  footerText={stats?.price}
                />
              </Grid> */}
            </Grid>
            }
           
          </Grid>
        }
        contentBody={
          <DataTable
            className='tranasction-in-progress-table'
            rows={state.rows}
            columns={columns}
            totalRowsCount={state.totalRowsCount}
            actionsMenu={primaryActions}
            contextActionsMenu={secondaryActions}
            defaultSortField={defaultSortColumn}
            defaultSortDescending={true}
            projectFields={state.projectFields}
            selectionMode={isUserAdmin  &&'multiple'}  // EVES-1789 added user role to action button
            onRowSelectionChange={onSelectedRow}
            data-cy='transactions-inprogress-table'
            componentName='Session'
            onChange={onTableChange}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            count={state.totalRowsCount}
            limit={state.limit}
            page={state.page}
            tablePagination={true}
            selectedRows={state.selectedRecords}
          >
            {/* <ConsumptionChart ref={consumptionChartRef} slot='row-detail' /> */}
            <ConnectorCell slot='connectorId' />
            <InActivityCell slot='currentTotalInactivitySecs' />
          </DataTable>
        }
      ></Content>

      {state.isChargingStationLookupModalOpen && (
        <LookupComponent
          data-cy='sessions-charging-plan-lookup'
          title={`${translate('chargers.select_chargers')}`}
          showLookupModal={state.isChargingStationLookupModalOpen}
          onModalClose={resetChargingPlanFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.CHARGINGSTATION}
          params={
            state.siteAreaID
              ? [
                  { key: 'Issuer', value: state.issuer },
                  { key: 'SiteAreaID', value: state.siteAreaID },
                ]
              : [{ key: 'Issuer', value: state.issuer }]
          }
          onSelect={onChargingStationSelect}
          defaultSortColumn='id'
        />
      )}

      {state.isSiteLookupModalOpen && (
        <LookupComponent
          title={`${translate('sites.select_sites')}`}
          showLookupModal={state.isSiteLookupModalOpen}
          onModalClose={resetSiteFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITE}
          params={[{ key: 'Issuer', value: state.issuer }]}
          onSelect={onSiteSelect}
          defaultSortColumn='name'
          data-cy='site-filter'   // EVES-1907
        />
      )}
      {state.isSiteAreaLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_site_areas')}`}
          showLookupModal={state.isSiteAreaLookupModalOpen}
          onModalClose={resetSiteAreaFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITEAREA}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'SiteID', value: state.siteID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onSiteAreaSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isTransactionDetailModalOpen && (
        <TransactionDetails
          transactionId={state.selectedTransactionId}
          chargingStationId={state.selectedChargingStationID}
          connectorId={state.selectedConnectorID}
          onClose={onTransactionDetailModalClose}
          popopOpen={state.isTransactionDetailModalOpen}
        />
      )}

      {state.isSubStationIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_substation_id')}`}
          showLookupModal={state.isSubStationIdLookupModalOpen}
          onModalClose={resetSubStationIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SUBSTATIONID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'SubStationID', value: state.subStationID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onSubStationIdSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isFeederIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_feeder_id')}`}
          showLookupModal={state.isFeederIdLookupModalOpen}
          onModalClose={resetFeederIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.FEEDERID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'FeederID', value: state.feederID || null },
            { key: 'WithSite', value: true },
            { key: 'SubStationID', value: state.subStationID || null },
          ]}
          onSelect={onFeederIdSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isTransformerIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_transformer_id')}`}
          showLookupModal={state.isTransformerIdLookupModalOpen}
          onModalClose={resetTransformerIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.TRANSFORMERID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'TransformerID', value: state.transformerID || null },
            { key: 'WithSite', value: true },
            { key: 'FeederID', value: state.feederID || null },
            { key: 'SubStationID', value: state.subStationID || null },
            { key: 'FeederID', value: state.feederID || null },
          ]}
          onSelect={onTransformerIdSelect}
          defaultSortColumn='name'
        />
      )}
    </>
  );
};

export default withContext(TransactionsInProgress);
